import React, { useEffect, useState } from 'react'
import { TeacherStudent } from 'pages'
import { Notification } from '@signpost/common/src/components'
import { SignalrContext } from '@signpost/common/src/contexts/SignalrContext'
import { useMe } from 'modules/Me'
import { useSchools } from 'modules/Schools'
import { useSignalr } from 'modules/Signalr'

import '../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../../../node_modules/daterangepicker/daterangepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'

let Listener = () => {}
window.authorization = {
    isAuthorized: true,
    set status(val) {
        this.isAuthorized = val
        Listener(val)
    },
    get status() {
        return this.isAuthorized
    },
    registerListener: listener => {
        Listener = listener
    }
}

const App = ({ authenticationState, login, logout }) => {
    const [isAuthorized, setIsAuthorized] = useState(true)
    const isLoggedIn = authenticationState === 'Authenticated'
    const { meData, isMeLoaded, meError, fetchMe } = useMe()
    const { schoolsData, areSchoolsLoaded, schoolsError, fetchSchools } = useSchools()
    const { initSignalr, isSignalrConnection, setSignalrValue, signalrConnection, signalrValue } = useSignalr()
    const defaultSchoolId = meData?.schoolId
    const [currentSchoolId, setCurrentSchoolId] = useState(() => localStorage.getItem('schoolId') || defaultSchoolId)
    const isSupportAdmin = meData?.role === 'SupportAdmin'

    window.authorization.registerListener(() => setIsAuthorized(false))

    useEffect(() => {
        if (!isLoggedIn || !isAuthorized) {
            login()
        } else {
            fetchMe(setCurrentSchoolId)
        }
    }, [])

    useEffect(() => {
        if (isSupportAdmin) {
            fetchSchools()
        }
    }, [isSupportAdmin])

    useEffect(() => {
        if (!isSignalrConnection && currentSchoolId) {
            initSignalr()
        }
    }, [isSignalrConnection, currentSchoolId])

    useEffect(() => {
        if (!localStorage.getItem('schoolId') && defaultSchoolId) {
            setCurrentSchoolId(defaultSchoolId)
        }
    }, [defaultSchoolId])

    return (
        <>
            <SignalrContext.Provider value={{ signalrValue, setSignalrValue, connection: signalrConnection }}>
                <TeacherStudent
                    handleLogout={logout}
                    globalState={{
                        me: {
                            data: meData,
                            isLoaded: isMeLoaded,
                            error: meError
                        },
                        schools: {
                            data: schoolsData,
                            isLoaded: areSchoolsLoaded,
                            error: schoolsError
                        },
                        currentSchoolId: currentSchoolId,
                        setCurrentSchoolId: setCurrentSchoolId
                    }}
                />
            </SignalrContext.Provider>
            <Notification />
            <div id="modal-root" />
        </>
    )
}

export default App
