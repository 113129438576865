import React from 'react'
import { Table } from 'components'

const VMImages = ({ icon, data, isMaxVMsClaimed, handleClaim }) => {
    const config = [
        { key: 'templateName', icon },
        { key: 'operatingSystem' },
        {
            key: 'customKey',
            buttons: [
                {
                    text: 'Claim',
                    timeout: 1000,
                    disabledLabel: 'Max. of VMs reached',
                    isCustomDisabled: isMaxVMsClaimed,
                    onClick: handleClaim
                }
            ]
        }
    ]

    return (
        <Table
            head={[
                ['Name', 50],
                ['Os', 25],
                ['', 25]
            ]}
            body={{ data, config }}
            emptyList={{ icon, text: 'Lab does not contain any VM images' }}
        />
    )
}

export default VMImages
