import React, { useState } from "react";
import styled from "styled-components";
import { SearchBar } from "@signpost/common/src/components";

import TableWrapper from "./Table";

const TableWrapperContainer = ({
  searchBar,
  title,
  head,
  body,
  actions,
  paginationProps,
  isBackground,
  onRowClick,
}) => {
  const Styling = styled.div`
    table th {
      font-weight: 600
      font-family: Open Sans, sans-serif
    }
    table td {
      font-family: Open Sans, sans-serif;
      word-break: break-all
      & button {
        margin: 2px
        font-weight: 600
      }
    }
`;

  return (
    <Styling>
      {searchBar && <SearchBar {...searchBar} />}
      <div className={isBackground ? "page-content" : ""}>
        <TableWrapper
          title={title}
          head={head}
          body={body}
          paginationProps={paginationProps}
          onRowClick={onRowClick}
          actions={actions}
        />
      </div>
    </Styling>
  );
};

export default TableWrapperContainer;
