import React from 'react'
import { Confirm } from 'components/Modal'

const ConfirmModal = ({ isLoaded, error, currentId, handleHide, handleConfirm }) => (
    <Confirm
        isLoaded={isLoaded}
        error={error}
        id={currentId}
        title="Request rejecting"
        message="Are you sure you want to reject this request?"
        confirmButtonText="Reject"
        handleHide={handleHide}
        handleConfirm={handleConfirm}
    />
)

export default ConfirmModal
