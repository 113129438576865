import React from 'react'
import { BoxLayout, Textarea } from 'components'
import { Modal, Form } from 'components/Modal'

const Credits = ({ data, modalData }) => {
    const { isModal, isLoaded, isStudent, error, formValues, handleToggleModal, handleChange, handleSave } = modalData
    const { currentBalance, maxCredits } = data || {}
    return (
        <>
            <BoxLayout
                icon="fas fa-coins"
                title="Credits"
                description={{
                    label: 'Remaining credits: ',
                    text:
                        'Credits are consumed by running virtual machines. When you run out of credits all your machines will be shut down.',
                    buttonText: isStudent ? 'Request credits' : '',
                    progressBar: { value: currentBalance, all: maxCredits },
                    onButtonClick: () => handleToggleModal('show')
                }}
                isOnTop
            />
            {isModal && (
                <Modal>
                    <Form
                        title="Request credits"
                        isLoaded={isLoaded}
                        error={error}
                        handleHide={handleToggleModal}
                        handleSave={handleSave}
                    >
                        <Textarea name="message" value={formValues.name} label="Message" onChange={handleChange} />
                    </Form>
                </Modal>
            )}
        </>
    )
}

export default Credits
