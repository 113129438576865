import React, { useState, useEffect } from 'react'
import { handleShowNotification } from '@signpost/common/src/helpers/requestStatus'
import { get, post, URLS } from '@signpost/common/src/helpers/api'
import { Form } from '@signpost/common/src/components'
import {
    SupportAdminVMClaimModal as supportAdminConfig,
    VMClaimModal as config,
    VMClaimModalKeys
} from '@signpost/common/src/formsConfig'
import { BoxLayout, Status } from 'components'
import { Modal } from 'components/Modal'
import VMImages from './VMImages'

import { FormFields } from './FormFields'

const icon = 'fas fa-cube'

const VMImagesContainer = ({ id, role, hasUserClaimedMaxVMs, hasTeacherRights, hasIPv6AddressSpace }) => {
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(true)
    const [error, setError] = useState(false)
    const [isMaxVMsClaimed, setIsMaxVMsClaimed] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentVMImage, setCurrentVMImage] = useState(null)
    const isSupportAdmin = role === 'SupportAdmin'
    const initialValues = isSupportAdmin ? supportAdminConfig.initialValues : config.initialValues
    const validationSchema = isSupportAdmin ? supportAdminConfig.validationSchema : config.validationSchema

    const handleGetVMImages = async () => {
        setIsLoaded(false)
        const url = URLS.labs.templates(id)
        try {
            const result = await get({ url })
            setData(result.data)
        } catch (err) {
            setError(err)
        }
        setIsLoaded(true)
    }

    useEffect(() => {
        if (hasUserClaimedMaxVMs) {
            setIsMaxVMsClaimed(true)
        }

        handleGetVMImages()
    }, [hasUserClaimedMaxVMs])

    const handleClaim = async VMImage => {
        setCurrentVMImage(VMImage)
        setIsModalOpen(true)
    }

    const handleSubmitClaim = async (values) => {
        if (!hasUserClaimedMaxVMs) {
            handleShowNotification('info')
            const url = URLS.virtualMachines.all
            const body = {
                labId: id,
                templateId: currentVMImage.id,
                iPv4Address: values[VMClaimModalKeys.iPv4],
                iPv6Address: values[VMClaimModalKeys.iPv6],
                iPv4AddressType: values[VMClaimModalKeys.iPv4AddressType],
                iPv6AddressType: values[VMClaimModalKeys.iPv6AddressType],
                ownerId: values.ownerId,
            }

            try {
                await post({ url, body })
                setIsModalOpen(!isModalOpen)
            } catch ({ response }) {
                handleShowNotification(response.status, response.data.Message)
            }
        }
    }

    return (
        <BoxLayout
            icon={icon}
            title="VM Images"
            isUnderlinedHeader={hasTeacherRights}
            isShadowLineHeader={!hasTeacherRights}
        >
            <Status isLoaded={isLoaded} error={error} isTableLoader>
                <VMImages icon={icon} data={data} isMaxVMsClaimed={isMaxVMsClaimed} handleClaim={handleClaim} />
                {isModalOpen && (
                    <Modal>
                        <Form
                            isLoaded={true}
                            error={null}
                            title="Set IPv4 and IPv6 addresses"
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            handleHide={() => setIsModalOpen(false)}
                            handleSave={handleSubmitClaim}
                            minHeight={270}
                        >
                            <FormFields
                                id={id}
                                hasIPv6AddressSpace={hasIPv6AddressSpace}
                                isSupportAdmin={isSupportAdmin}
                            />
                        </Form>
                    </Modal>
                )}
            </Status>
        </BoxLayout>
    )
}

export default VMImagesContainer
