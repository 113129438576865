import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import {
    Autocomplete,
    Input,
    Radio,
} from '@signpost/common/src/components'
import { VMClaimModalKeys } from '@signpost/common/src/formsConfig'
import { usePotentialOwners } from './usePotentialOwners'

export const FormFields = ({ id, isSupportAdmin, hasIPv6AddressSpace }) => {
    const { setFieldValue, values } = useFormikContext()
    const {
        arePotentialOwnersLoaded,
        fetchPotentialOwners,
        getPotentialOwnersOptionLabel,
        potentialOwnersOptions,
        renderPotentialOwnersOption,
    } = usePotentialOwners(id)

    useEffect(() => {
        if (id && isSupportAdmin) {
            fetchPotentialOwners(id)
        }
    }, [id])

    const radioValues = [
        { value: 'Dynamic', label: 'dynamic' },
        { value: 'Static', label: 'static' }
    ]

    const handleChangeRadio = e => {
        const name = e.target.name
        const value = e.target.value

        if (name === 'iPv4') {
            setFieldValue(VMClaimModalKeys.iPv4AddressType, value)
            if (value === 'Dynamic') {
                setFieldValue(VMClaimModalKeys.iPv4, '')
            }
        }

        if (name === 'iPv6') {
            setFieldValue(VMClaimModalKeys.iPv6AddressType, value)
            if (value === 'Dynamic') {
                setFieldValue(VMClaimModalKeys.iPv6, '')
            }
        }
    }

    return (
      <>
        <p>If no address is filled, it will be assigned dynamically.</p>
        <Radio
            label="IPv4 Address"
            name="iPv4"
            value={values[VMClaimModalKeys.iPv4AddressType]}
            values={radioValues}
            onChange={handleChangeRadio}
        />
        <Input
            name={VMClaimModalKeys.iPv4}
            isDisabled={values[VMClaimModalKeys.iPv4AddressType] === 'Dynamic'}
            placeholder="i.e. 10.0.0.4"
            isRequired={true}
        />
        <Radio
            label="IPv6 Address"
            name="iPv6"
            value={values[VMClaimModalKeys.iPv6AddressType]}
            values={radioValues}
            isAllDisabled={!hasIPv6AddressSpace}
            onChange={handleChangeRadio}
        />
        <Input
            name={VMClaimModalKeys.iPv6}
            isDisabled={!hasIPv6AddressSpace || values[VMClaimModalKeys.iPv6AddressType] === 'Dynamic'}
            placeholder="i.e. ace:cab:dca:deed::4"
            isRequired={true}
        />
        {isSupportAdmin && (
            <Autocomplete
                label="User"
                name={VMClaimModalKeys.ownerId}
                options={potentialOwnersOptions}
                getOptionLabel={getPotentialOwnersOptionLabel}
                renderOption={renderPotentialOwnersOption}
                isDisabled={!arePotentialOwnersLoaded}
                placeholder="select user"
            />
        )}
      </>
    )
}
