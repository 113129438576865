import React from 'react'
import { useGet } from '@signpost/common/src/helpers/customHooks'
import { Status } from 'components'
import { Info, VirtualMachines, VMImages, Students } from './components'
import { URLS } from '@signpost/common/src/helpers/api'

const LabContainer = ({ id, role, email, handleChangeView }) => {
    const { isLoaded, error, data } = useGet(URLS.labs.single(id))
    const { isLoaded: isLoadedVMs, error: errorVMs, data: dataVMs } = useGet(URLS.labs.virtualMachines(id))

    const hasTeacherRights = ['Teacher', 'SupportAdmin'].includes(role)
    const vmsCreatedByCurrentUser = dataVMs?.filter(({ owner }) => owner === email).length
    const isMaxVirtualMachinesPerLab = data?.amountOfVirtualMachines >= data?.maxVirtualMachinesPerLab
    const isMaxForTeacher = isMaxVirtualMachinesPerLab
    const isMaxForStudent = isMaxVirtualMachinesPerLab || vmsCreatedByCurrentUser >= data?.maxVirtualMachinesPerStudent
    const hasUserClaimedMaxVMs = hasTeacherRights ? isMaxForTeacher : isMaxForStudent

    if (!data?.id) {
        return null
    }

    return (
        <Status isLoaded={isLoaded && isLoadedVMs} error={error || errorVMs}>
            <Info
                data={data}
                hasTeacherRights={hasTeacherRights}
                hasIPv6AddressSpace={data.hasIPv6AddressSpace}
                handleChangeView={handleChangeView}
            />
            <VirtualMachines id={data.id} role={role} email={email} hasIPv6AddressSpace={data.hasIPv6AddressSpace} />
            <VMImages
                id={data.id}
                hasTeacherRights={hasTeacherRights}
                role={role}
                hasIPv6AddressSpace={data.hasIPv6AddressSpace}
                hasUserClaimedMaxVMs={hasUserClaimedMaxVMs}
            />
            {hasTeacherRights && <Students id={data.id} />}
        </Status>
    )
}

export default LabContainer
