import React, { useState, useEffect, useContext } from 'react'
import get from 'lodash/get'
import axios from 'axios'
import { get as getApi, put, post, adminPost, remove, API, URLS } from '@signpost/common/src/helpers/api'
import { handleShowNotification } from '@signpost/common/src/helpers/requestStatus'
import handleDownload from '@signpost/common/src/helpers/download'
import { osType } from '@signpost/common/src/const'
import { SignalrContext } from '@signpost/common/src/contexts/SignalrContext'
import { BoxLayout, Status } from 'components'
import VirtualMachines from './VirtualMachines'
import { VMClaimModalKeys } from '@signpost/common/src/formsConfig'
import { signalrConst } from 'modules/Signalr'

const icon = 'fas fa-desktop'
const defaultModalData = { userName: '', password: '', host: '', port: '', operatingSystem: '' }

const VirtualMachinesContainer = ({ id, role, email, hasIPv6AddressSpace }) => {
    const [isVirtualMachinesLoaded, setIsVirtualMachinesLoaded] = useState(true)
    const [virtualMachinesError, setVirtualMachinesError] = useState(null)
    const [virtualMachines, setVirtualMachines] = useState([])

    const [isModal, setIsModal] = useState(false)
    const [modalData, setModalData] = useState(defaultModalData)
    const [modalError, setModalError] = useState(null)
    const [isModalLoaded, setIsModalLoaded] = useState(true)
    const [currentRowId, setCurrentRowId] = useState(null)

    const [isEditIPModalOpen, setIsEditIPModalOpen] = useState(false)
    const [iPv4AddressType, setIPv4AddressType] = useState('Dynamic')
    const [iPv6AddressType, setIPv6AddressType] = useState('Dynamic')
    const [currentRow, setCurrentRow] = useState({})
    const [areSecurityRulesLoaded, setAreSecurityRulesLoaded] = useState(false)
    const [isEditPortLoaded, setIsEditPortLoaded] = useState(true)
    const [isIpLoaded, setIsIpLoaded] = useState(true)

    const [areDNSLoaded, setAreDNSLoaded] = useState(true)

    const [isEditContributorsModalOpen, setIsEditContributorsModalOpen] = useState(false)
    const [arePotentialContributorsLoaded, setArePotentialContributorsLoaded] = useState(true)
    const [potentialContributorsError, setPotentialContributorsError] = useState(null)
    const [potentialContributors, setPotentialContributors] = useState(null)
    const [chosenContributors, setChosenContributors] = useState([])
    const [contributorsModalVmId, setContributorsModalVmId] = useState(null)

    const signalr = useContext(SignalrContext)
    const signalrConnection = signalr?.connection
    const hasTeacherRights = ['Teacher', 'SupportAdmin'].includes(role)

    useEffect(() => {
        const signalrData = get(signalr, ['signalrValue', 'value', 'data'], {})
        let synchronizedVirtualMachines = [...virtualMachines]
        const shouldVirtualMachinesBeUpdated = virtualMachines.some(
            virtualMachine => virtualMachine.id === signalrData.id
        )

        if (signalrData.ownerId) {
            if (shouldVirtualMachinesBeUpdated) {
                synchronizedVirtualMachines = virtualMachines.reduce((acc, virtualMachine) => {
                    const foundVirtualMachineToUpdate = virtualMachine.id === signalrData.id
                    const isDeleted = signalrData.isDeleted

                    if (foundVirtualMachineToUpdate) {
                        if (isDeleted) {
                            return acc
                        } else {
                            return [...acc, signalrData]
                        }
                    } else {
                        return [...acc, virtualMachine]
                    }
                }, [])
            } else {
                const newVMCreatedInThisLab = signalrData.labId === id
                if (newVMCreatedInThisLab) {
                    synchronizedVirtualMachines.push(signalrData)
                }
            }
        }

        if (signalrData.ipType) {
            if (signalrData.ipType === 'IPv4') {
                setIPv4AddressType(signalrData.ipAllocationMethod)
            }

            if (signalrData.ipType === 'IPv6') {
                setIPv6AddressType(signalrData.ipAllocationMethod)
            }

            if (signalrData.isSuccessful) {
                handleShowNotification(200, `IP for ${signalrData.virtualMachineName} has been updated.`)
                setIsEditIPModalOpen(false)
                handleGetVirtualMachines()
            }
        }

        setVirtualMachines(synchronizedVirtualMachines)
    }, [signalr, id])

    const handleGetPotentialContributors = async query => {
        const vmId = contributorsModalVmId
        const url = URLS.virtualMachines.potentialContributors(vmId)
        if (query.length > 2) {
            setArePotentialContributorsLoaded(false)

            try {
                const result = await getApi({ url, query: { query } })
                setPotentialContributors(result.data)
            } catch (err) {
                setPotentialContributorsError(err)
            } finally {
                setArePotentialContributorsLoaded(true)
            }
        }
    }

    const handleGetVirtualMachines = async () => {
        const url = URLS.labs.virtualMachines(id)
        setIsVirtualMachinesLoaded(false)

        try {
            const result = await getApi({ url })
            setVirtualMachines(result.data)
        } catch (err) {
            setVirtualMachinesError(err)
        } finally {
            setIsVirtualMachinesLoaded(true)
        }
    }

    useEffect(() => {
        const connection = signalrConnection
        handleGetVirtualMachines()
        if (connection) {
            connection.invoke('Subscribe', { Type: signalrConst.VirtualMachineStateChangedNotification, LabId: id })
            connection.invoke('Subscribe', { Type: signalrConst.ClosingIdleVirtualMachineNotification, LabId: id })
        }

        return () => {
            if (connection) {
                connection.invoke('Unsubscribe', {
                    Type: signalrConst.VirtualMachineStateChangedNotification,
                    LabId: id
                })
                connection.invoke('Unsubscribe', {
                    Type: signalrConst.ClosingIdleVirtualMachineNotification,
                    LabId: id
                })
            }
        }
    }, [])

    const handleConnectVM = async row => {
        setIsModal(true)
        setIsModalLoaded(false)

        if (row.operatingSystem === osType.windows) {
            setCurrentRowId(row.id)
        }

        const url = URLS.virtualMachines.single(row.id)
        try {
            const result = await getApi({ url })
            setModalData({ ...result.data, operatingSystem: row.operatingSystem })
            setIsModalLoaded(true)
        } catch (err) {
            setModalError({ data: { Message: 'Request error' } })
            setIsModalLoaded(true)
        }
    }

    const handleGetNetworkSecurityGroup = async row => {
        handleShowNotification('info', 'Request sent')
        setIsVirtualMachinesLoaded(false)

        try {
            const url = URLS.virtualMachines.networkSecurityGroup(row.id)
            const result = await adminPost({ url })
            const updatedVMIndex = virtualMachines.findIndex(vm => vm.id === row.id)
            const VMCopy = [...virtualMachines]
            VMCopy[updatedVMIndex] = { ...VMCopy[updatedVMIndex], hasNetworkSecurityGroup: true }

            setVirtualMachines(VMCopy)
            handleShowNotification(result.status, 'Connection ready to start')
        } catch ({ response }) {
            handleShowNotification(response.status, 'Add NSG error')
        } finally {
            setIsVirtualMachinesLoaded(true)
        }
    }

    const getModalData = async row => {
        setAreSecurityRulesLoaded(false)
        setAreDNSLoaded(false)
        setIsIpLoaded(false)

        const srUrl = URLS.virtualMachines.securityRules(row.id)
        const dnsUrl = URLS.virtualMachines.allDns(row.id)
        const ipUrl = URLS.virtualMachines.ipConfiguration(row.id)

        try {
            const securityRules = await getApi({ url: srUrl })
            const dns = await getApi({ url: dnsUrl })
            const ip = await getApi({ url: ipUrl })

            if (!ip.data.isIPV4ChangeInProgress) {
                setCurrentRow(row)
                setIPv4AddressType(row['iPv4AllocationMethod'])
                setIPv6AddressType(row['iPv6AllocationMethod'])
                setIsIpLoaded(true)
            }
            setCurrentRow({ ...row, securityRules: securityRules.data, dns: dns.data })
        } catch (err) {
            setModalError({ data: { Message: 'Request error' } })
        } finally {
            setAreSecurityRulesLoaded(true)
            setAreDNSLoaded(true)
        }
    }

    const handleToggleModal = () => {
        setIsModal(!isModal)
        setModalData(defaultModalData)
        setModalError(null)
    }
    const handleToggleEditIPModal = row => {
        const isOpening = row?.id

        if (isOpening) {
            getModalData(row)
        }
        setIsEditIPModalOpen(!isEditIPModalOpen)
    }

    const handleToggleContributorsModal = row => {
        const isOpening = row?.id

        if (isOpening) {
            const currentVmContributors = virtualMachines.find(vm => vm.id === row.id).contributors
            setChosenContributors(currentVmContributors)
            setContributorsModalVmId(row.id)
        } else {
            setChosenContributors(null)
            setPotentialContributors(null)
        }
        setIsEditContributorsModalOpen(!isEditContributorsModalOpen)
    }

    const handleEditIP = async formData => {
        var iPv4Address = formData[VMClaimModalKeys.iPv4] || ''
        var iPv6Address = formData[VMClaimModalKeys.iPv6] || ''

        handleShowNotification('info')
        const url = URLS.virtualMachines.setIP(currentRow.id)
        const body = { iPv4Address, iPv6Address, iPv4AddressType, iPv6AddressType }

        try {
            await post({ url, body })
            setIsEditIPModalOpen(!isEditIPModalOpen)
        } catch ({ response }) {
            handleShowNotification(response.status, response.data.Message)
        }
    }

    const handleChangeRadio = e => {
        const name = e.target.name
        const value = e.target.value

        if (name === 'iPv4') {
            setIPv4AddressType(value)
        }

        if (name === 'iPv6') {
            setIPv6AddressType(value)
        }
    }

    const handleDownloadRdpFile = async () => {
        setIsModalLoaded(false)

        try {
            await getApi({
                url: `${URLS.virtualMachines.rdp(currentRowId)}`,
                responseType: 'blob'
            }).then(response => {
                handleDownload(response, 'Windows Config.rdp')
            })
            setCurrentRowId(null)
            setIsModalLoaded(true)
        } catch (err) {
            setIsModalLoaded(true)
            setModalError({ data: { Message: 'Downloading error' } })
        }
    }

    const handleSumbitContributors = async () => {
        const vmId = contributorsModalVmId
        const url = URLS.virtualMachines.contributors(vmId)
        const body = { contributors: chosenContributors.map(({ id }) => id) }
        setIsVirtualMachinesLoaded(false)
        handleToggleContributorsModal()
        try {
            const result = await put({ url, body })
            handleGetVirtualMachines()
            handleShowNotification(result?.status)
        } catch (error) {
            setIsVirtualMachinesLoaded(true)
            handleShowNotification(error?.status)
        }
    }

    const onRemoveChosen = el => {
        const filtered = chosenContributors.filter(user => user.id !== el.id)
        setChosenContributors(filtered)
    }

    const onContributorsRowClick = row => {
        const chosenIds = chosenContributors.map(el => el.id)
        if (chosenIds.includes(row.id)) {
            return null
        }
        setChosenContributors([...chosenContributors, row])
    }

    const handleEditPort = async data => {
        const url = URLS.securityRules.all
        const body = { ...data, virtualMachineId: currentRow.id }
        setIsEditPortLoaded(false)
        try {
            const result = await post({ url, body })
            handleShowNotification(result?.status)
        } catch ({ response }) {
            handleShowNotification(response?.status, response?.data?.Message)
        } finally {
            setIsEditPortLoaded(true)
            setIsEditIPModalOpen(!isEditIPModalOpen)
        }
    }

    const handleEditDNS = async data => {
        const url = URLS.virtualMachines.allDns(currentRow.id)
        const body = data
        setAreDNSLoaded(false)
        try {
            const result = await post({ url, body })
            handleShowNotification(result?.status)
        } catch ({ response }) {
            handleShowNotification(response?.status, response?.data?.Message)
        } finally {
            setAreDNSLoaded(true)
            setIsEditIPModalOpen(false)
        }
    }

    const handleDeletePort = async row => {
        setIsEditPortLoaded(false)
        const id = row.id
        const url = URLS.securityRules.byId(id)
        try {
            const result = await remove({ url })
            handleShowNotification(result?.status)
        } catch ({ response }) {
            handleShowNotification(response?.status)
        } finally {
            setIsEditPortLoaded(true)
            handleToggleEditIPModal()
        }
    }

    const handleDeleteDNS = async row => {
        setAreDNSLoaded(false)

        const vmId = currentRow.id
        const dnsServerId = row.id
        const url = URLS.virtualMachines.dnsById(vmId, dnsServerId)
        try {
            const result = await remove({ url })
            handleShowNotification(result?.status)
        } catch ({ response }) {
            handleShowNotification(response?.status)
        } finally {
            setAreDNSLoaded(true)
            handleToggleEditIPModal()
        }
    }

    const modalRdpProps = {
        isModal,
        isModalLoaded,
        modalError,
        modalData,
        handleToggleModal,
        handleDownloadRdpFile
    }

    const modalEditIPProps = {
        isEditIPModalOpen,
        iPv4AddressType,
        iPv6AddressType,
        hasIPv6AddressSpace,
        iPv4Address: currentRow['iPv4Address'],
        iPv6Address: currentRow['iPv6Address'],
        handleToggleEditIPModal,
        handleChangeRadio,
        handleEditIP,
        handleEditPort,
        handleDeletePort,
        areSecurityRulesLoaded,
        securityRules: currentRow.securityRules,
        hasNetworkSecurityGroup: currentRow.hasNetworkSecurityGroup,
        isEditPortLoaded,
        isIpLoaded
    }

    const modalEditContributors = {
        isEditContributorsModalOpen,
        chosenContributors,
        potentialContributors,
        arePotentialContributorsLoaded,
        potentialContributorsError,
        handleGetPotentialContributors,
        handleSumbitContributors,
        handleToggleContributorsModal,
        onRemoveChosen,
        onContributorsRowClick
    }

    const modalEditDNSProps = {
        areDNSLoaded,
        DNSData: currentRow.dns,
        handleDeleteDNS,
        handleEditDNS
    }

    return (
        <BoxLayout
            icon={icon}
            title="Virtual Machines"
            isUnderlinedHeader={hasTeacherRights}
            isShadowLineHeader={!hasTeacherRights}
        >
            <Status isLoaded={isVirtualMachinesLoaded} error={virtualMachinesError} isTableLoader>
                <VirtualMachines
                    icon={icon}
                    data={virtualMachines}
                    role={role}
                    email={email}
                    modalRdpProps={modalRdpProps}
                    modalEditIPProps={modalEditIPProps}
                    modalEditContributors={modalEditContributors}
                    modalEditDNSProps={modalEditDNSProps}
                    handleConnectVM={handleConnectVM}
                    handleGetNetworkSecurityGroup={handleGetNetworkSecurityGroup}
                />
            </Status>
        </BoxLayout>
    )
}

export default VirtualMachinesContainer
