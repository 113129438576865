import React from 'react'
import './Status.css'

const Status = ({ error, isLoaded, isTableLoader, children }) => {
    if (!isLoaded) {
        if (isTableLoader) {
            return (
                <div className="sc-box sc-table-wrapper ">
                    <div className="sc-loader" />
                </div>
            )
        }
        return <div className="sc-loader" />
    }

    if (error) {
        return <div>Error: {error.data && error.data.Message}</div>
    }

    return children
}

export default Status
