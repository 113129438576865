export const powerStatesCodes = {
    deallocated: 'PowerState/deallocated',
    running: 'PowerState/running',
    starting: 'PowerState/starting',
    deallocating: 'PowerState/deallocating',
    stopping: 'PowerState/stopping',
    stopped: 'PowerState/stopped',
    unknown: 'Unknown',
    creating: 'Creating',
    failed: 'Failed',
    empty: ''
}

export const powerStates = {
    [powerStatesCodes.deallocated]: 'deallocated',
    [powerStatesCodes.running]: 'running',
    [powerStatesCodes.starting]: 'starting',
    [powerStatesCodes.deallocating]: 'deallocating',
    [powerStatesCodes.stopping]: 'stopping',
    [powerStatesCodes.stopped]: 'stopped',
    [powerStatesCodes.unknown]: 'unknown',
    [powerStatesCodes.creating]: 'creating',
    [powerStatesCodes.failed]: 'failed'
}

export const powerStatesUserCodes = {
    deallocated: 'Deallocated',
    deallocating: 'Deallocating..',
    running: 'Running',
    starting: 'Starting..',
    stopped: 'Stopped',
    processing: 'Processing..',
    unknown: 'Initializing..',
    creating: 'Creating..',
    failed: 'Failed'
}

export const powerStatesCommands = {
    start: 'Start',
    stop: 'Stop'
}

export const columnKeys = {
    powerStates: 'status'
}

export const deallocateStartVmButtonEnabledPowerStates = [powerStatesCodes.running, powerStatesCodes.deallocated]

export const connectVmButtonEnabledPowerStates = [powerStatesCodes.running]

export const osType = {
    windows: 'Windows',
    linux: 'Linux'
}

export const vmButtonsTypes = {
    power: "power",
    connect: "connect",
    share: "share"
}

export const vmCreationStates = {
    unknown: 0,
    creating: 1,
    failed: 2,
    done: 3
}
