import React from 'react'
import './Input.css'

const Input = ({
    label,
    name,
    type = 'text',
    id,
    placeholder,
    value,
    min,
    max,
    isRequired,
    onChange,
    onClick,
    secondLabel
}) => (
    <div className="sc-input">
        {label && <label htmlFor={name}>{label}</label>}
        <input
            type={type}
            id={id || name}
            placeholder={placeholder}
            value={value}
            min={min}
            max={max}
            required={isRequired}
            onChange={e => onChange && onChange(e, name)}
            onClick={onClick}
        />
        {secondLabel && <label htmlFor={name}>{secondLabel}</label>}
    </div>
)

export default Input
