import React from 'react'
import { BoxLayout, Textarea } from 'components'
import { Modal, Form } from 'components/Modal'

const Info = ({ name, description, modalData, hasTeacherRights, handleChangeView }) => {
    const { isModal, isModalLoaded, error, updatedDescription, handleToggleModal, handleChange, handleSave } = modalData

    return (
        <>
            <BoxLayout
                icon="fas fa-flask"
                title={name}
                description={{
                    label: 'Description',
                    text: description,
                    buttonText: hasTeacherRights ? 'Edit' : '',
                    onButtonClick: () => handleToggleModal('show')
                }}
                isOnTop
                handleGoBack={() => handleChangeView('labsList')}
                isUnderlinedHeader={hasTeacherRights}
                isShadowLineHeader={!hasTeacherRights}
            />
            {isModal && (
                <Modal>
                    <Form
                        title={`Edit Lab ${name}`}
                        isLoaded={isModalLoaded}
                        error={error}
                        handleHide={handleToggleModal}
                        handleSave={handleSave}
                    >
                        <Textarea
                            name="description"
                            value={updatedDescription}
                            label="Lab Description"
                            secondLabel="Edit the description of the lab. The description is shown to students on the lab information panel when viewing any VM linked to the lab."
                            onChange={handleChange}
                        />
                    </Form>
                </Modal>
            )}
        </>
    )
}

export default Info
