import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox as MaterialCheckbox,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledCheckbox = withStyles({
  root: {
    color: "#8ec154",
    "&$checked": {
      color: "#8ec154",
    },
  },
  checked: {},
})((props) => <MaterialCheckbox color="default" {...props} />);

const Checkbox = ({ label, name, isChecked, isDisabled, handleChange }) => (
  <FormControl>
    <FormControlLabel
      label={label}
      control={
        <StyledCheckbox
          name={name}
          checked={isChecked}
          disabled={isDisabled}
          onChange={() => handleChange(name)}
        />
      }
    />
  </FormControl>
);

export default Checkbox;
