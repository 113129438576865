import { toast } from 'react-toastify'

export const successToast = message => toast(message, { type: 'success' })
export const infoToast = message => toast(message, { type: 'info' })
export const errorToast = (message, autoClose) => toast(message, { type: 'error', autoClose })


export const handleShowNotification = (status, message, autoClose) => {
    switch (status) {
        case 200:
        case 204:
            return successToast(message || 'Success!')
        case 400:
            return toast(message || 'Bad Request!', { type: 'error' })
        case 401:
            return errorToast(message || 'Unauthorized, sign in with a different account!')
        case 403:
            return errorToast(message || "You don't have permission!")
        case 404:
            return errorToast(message || 'Not found!')
        case 500:
            return errorToast(message || 'Request failed!')
        case 'info':
            return infoToast(message || 'Request sent')
        case 'error':
            return errorToast(message, autoClose)
        default:
            return null
    }
}
