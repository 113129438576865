import React, { useState } from "react";
import { FormLabel, FormHelperText, makeStyles } from "@material-ui/core";
import FormRadio from "./FormRadio";

const useStyles = makeStyles((theme) => {
  return {
    radiosWrapper: {
      width: "100%",
      marginBottom: 0,
      "& .MuiButtonBase-root:hover": {
        background: "rgba(139, 195, 74, 0.04)",
      },
      "& .MuiButtonBase-root.Mui-checked": {
        color: "#8ec154",
      },
    },
    formControl: {
      width: "100%",
      marginBottom: 0,
    },
    error: {
      marginTop: "-18px",
      marginBottom: "10px",
    },
  };
});

const FormRadioGroup = ({ config, name, groupLabel }) => {
  const [error, setError] = useState("");
  const handleSetError = (error) => setError(error);

  const classes = useStyles();

  return (
    <>
      <div className={classes.radiosWrapper}>
        <FormLabel className={classes.formControl} error={!!error}>
          {groupLabel}
        </FormLabel>
        {config.map(({ value, label }) => {
          return (
            <FormRadio
              key={label}
              label={label}
              name={name}
              type="radio"
              value={value}
              handleSetError={handleSetError}
            />
          );
        })}
        {error && (
          <FormHelperText error={true} className={classes.error}>
            {error}
          </FormHelperText>
        )}
      </div>
    </>
  );
};

export default FormRadioGroup;
