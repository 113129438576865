const handleDownload = (response, filenameWithExtension) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filenameWithExtension)
    document.body.appendChild(link)
    link.click()
}

export default handleDownload
