import React, { useEffect } from "react";
import { FormControlLabel, Radio as MaterialRadio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useField } from "formik";
// const StyledCheckbox = withStyles({
//   root: {
//     color: "#8ec154",
//     "&$checked": {
//       color: "#8ec154",
//     },
//   },
//   checked: {},
// })((props) => <MaterialCheckbox color="default" {...props} />);

const FormRadio = ({ label, handleSetError, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  useEffect(() => {
    handleSetError(errorText);
  }, [errorText]);

  return (
    <FormControlLabel {...field} control={<MaterialRadio />} label={label} />
  );
};

export default FormRadio;
