import React, { useState } from "react";
import { Box, Checkbox, Typography, makeStyles } from "@material-ui/core";
import { useField } from "formik";

const useStyles = makeStyles((theme) => {
  return {
    checkbox: {
      display: "inline-block",
    },
    typography: {
      display: "inline-block",
      position: "relative",
      bottom: -2,
      color: !isChecked && "#7d7e7f",
    },
  };
});

const FormCheckboxContainer = ({
  label,
  boxClassName,
  className,
  m,
  p,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [field] = useField(props);

  const classes = useStyles();

  const toggleIsChecked = () => setIsChecked(!isChecked);

  return (
    <Box m={m} p={p} className={boxClassName}>
      <Checkbox
        className={`${classes.checkbox} ${className}`}
        color="primary"
        {...field}
        onClick={toggleIsChecked}
      />
      <Typography className={classes.typography}>{label}</Typography>
    </Box>
  );
};

export default FormCheckboxContainer;
