import React from 'react'
import { Button } from 'components'
import './Card.css'

const renderValue = (boolValues, data, value) => {
    const boolValueLabel = boolValues && (data[value] ? boolValues[0] : boolValues[1])
    return boolValues ? boolValueLabel : data[value]
}

const renderCardBody = (config, data) =>
    config.map(({ label, value, boolValues, suffix, isBold }) => {
        return (
            <div key={value} className="sc-card-values">
                {label && <h6>{label}</h6>}
                <p className={isBold ? 'isBold' : ''}>
                    {renderValue(boolValues, data, value)} {suffix}
                </p>
            </div>
        )
    })

const Card = ({ config, data, isSimpleView, handleChangeView }) => (
    <div className={`sc-card${isSimpleView ? ' isSimpleView' : ''}`}>
        <div className="sc-card-icon">
            <div className="sc-card-icon-background" />
            <i className="fas fa-flask" />
        </div>
        {renderCardBody(config, data)}
        <div>
            <Button text="Open" onClick={() => handleChangeView('lab', data.id)} />
        </div>
    </div>
)

export default Card
