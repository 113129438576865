import React from 'react'
import './Footer.css'

const Footer = () => (
    <div className="sc-footer">
        <div className="sc-footer-top">
            <p>Academic software is a product of </p>
            <a href="https://www.signpost.be/academicsoftware" target="blank">
                Academic Software ltd
            </a>
            <p>. Part of </p>
            <a href="https://signpost.eu/" target="blank">
                Signpost Group
            </a>
            <p>.</p>
        </div>
        <div className="sc-footer-bottom">
            <p>&#169; 2020 - </p>
            <a href="https://www.academicsoftware.eu/privacy-policy">Privacy Policy</a>
            <p> - </p>
            <a href="https://www.academicsoftware.eu/terms-and-conditions">Terms and Conditions</a>
        </div>
    </div>
)

export default Footer
