import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const getOptionLabel = (schools) => (schoolId) => schools?.find((school) => school.id === schoolId)?.name || '';

const getOptions = (schools) => schools.map((school) => school.id)

const SchoolSelect = ({ defaultValue = '', setValue, schools, loading, value }) => {
  useEffect(() => {
    if (!localStorage.getItem('schoolId')) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      localStorage.setItem('schoolId', value);
    }
  }, [value]);;

  const handleChange = (_, newValue) => {
    setValue(newValue);
    location.reload();
  }
  
  return (
    <Autocomplete
      size="small"
      value={value}
      onChange={handleChange}
      id="school-combo-box"
      options={getOptions(schools)}
      getOptionLabel={getOptionLabel(schools)}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} variant="outlined" label="School" />}
      disableClearable
      loading={loading}
    />
  )
};

SchoolSelect.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  schools: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SchoolSelect;
