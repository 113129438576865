import React, { useState, Fragment } from 'react'
import { Button } from 'components'
import './Table.css'
import get from 'lodash/get'
import {
    deallocateStartVmButtonEnabledPowerStates,
    connectVmButtonEnabledPowerStates,
    powerStatesUserCodes,
    powerStatesCodes,
    powerStates,
    columnKeys,
    vmButtonsTypes
} from '@signpost/common/src/const'

const renderHead = head => (
    <thead>
        <tr>
            {head.map(col => {
                return (
                    <th key={col[0]} scope="col" style={{ width: col[1] + '%' }}>
                        {col[0]}
                    </th>
                )
            })}
        </tr>
    </thead>
)

const renderPowerStateButton = (row, buttonData, className, powerState) => {
    const isDeallocateStartVmButtonDisabled = !deallocateStartVmButtonEnabledPowerStates.includes(powerState)

    return (
        <Button
            text={buttonData && buttonData.text}
            className={className}
            isDisabled={isDeallocateStartVmButtonDisabled}
            onClick={() => buttonData && buttonData.onClick(row)}
        />
    )
}

const renderShareVmButton = (row, buttonData, className) => {
    return <Button text="Share" className={className} onClick={() => buttonData && buttonData.onClick(row)} />
}

const renderConnectVmButton = (row, button, className, powerState) => {
    const isConnectVmButtonDisabled =
        !connectVmButtonEnabledPowerStates.includes(powerState) || !row.hasNetworkSecurityGroup

    return (
        <Button
            text="Connect"
            className={className}
            isDisabled={isConnectVmButtonDisabled}
            onClick={() => button.onClick(row)}
        />
    )
}

const generateCellData = (row, key) => {
    const innerData = row[key]

    if (key === columnKeys.powerStates) {
        const powerState = get(powerStates, innerData, get(powerStates, powerStatesCodes.unknown))

        const powerStatesUserCode = get(powerStatesUserCodes, powerState)

        return powerStatesUserCode
    }

    return innerData || '-'
}

const renderBodyTd = ({ config, row, isHidden, isFullWidth, removeCustomButton }) => {
    const renderDate = (isDate, rowKey) => {
        return isDate && rowKey && rowKey.slice(0, 10)
    }

    const renderHiddenButton = button => {
        const powerState = get(row, [columnKeys.powerStates])
        const rowKey = row[button.key]
        const buttonData = button[rowKey]
        const defaultButton = button.default
        const { className } = button
        const showButton =
            !button.hideCondition ||
            (!row[button.hideCondition] && !['Unknown', 'Creating', 'Failed'].includes(powerState))

        const customButton = showButton && (
            <Button text={button.text} extraText={button.extraText} onClick={() => button.onClick(row)} />
        )

        return (
            <>
                {button.type === vmButtonsTypes.power && renderPowerStateButton(row, buttonData, className, powerState)}
                {button.type === vmButtonsTypes.connect && renderConnectVmButton(row, button, className, powerState)}
                {button.type === 'custom' &&
                    (removeCustomButton ? removeCustomButton(row, button) && customButton : customButton)}
                {!button.type && <Button text={defaultButton && defaultButton.text} className={className} isDisabled />}
            </>
        )
    }

    const rowKeys = config.map(el => el.key)

    return rowKeys.map((key, keyIndex) => {
        const { icon, hidden, fullWidthElement, statusesInfo, isDate } = config[keyIndex]
        const { buttons, style } = config[keyIndex]

        if (key === 'customKey') {
            return (
                <td key="customKey" className="custom-td" style={style}>
                    {buttons &&
                        buttons.map(
                            ({ text, className, disabledLabel, isCustomDisabled, isLoading, timeout, onClick }) => {
                                return (
                                    <Fragment key={text}>
                                        <Button
                                            text={text}
                                            className={className}
                                            disabledTimeout={timeout}
                                            isDisabled={isCustomDisabled}
                                            isLoading={isLoading}
                                            onClick={e => {
                                                e.stopPropagation()
                                                onClick(row)
                                            }}
                                        />
                                        {isCustomDisabled && <span> {disabledLabel}</span>}
                                    </Fragment>
                                )
                            }
                        )}
                </td>
            )
        }

        if (isHidden) {
            const rowData = hidden && hidden.info && row[hidden.info.key]
            return (
                <td key={keyIndex} style={style}>
                    <div className="sc-table-hidden">
                        {hidden && (
                            <>
                                {hidden.button && renderHiddenButton(hidden.button)}
                                {hidden.secondButton && renderHiddenButton(hidden.secondButton)}
                                {hidden.thirdButton && renderHiddenButton(hidden.thirdButton)}
                                {hidden.info && (
                                    <div>
                                        {hidden.info.prefix}
                                        {hidden.info.showLength ? rowData.length : rowData}
                                        {hidden.info.tooltip && (
                                            <>
                                                {hidden.info.tooltip.isListStyle
                                                    ? rowData.length > 0 && (
                                                          <ul className="sc-table-tooltip">
                                                              {rowData.map(el => (
                                                                  <li key={el[hidden.info.tooltip.key]}>
                                                                      {el[hidden.info.tooltip.key]}
                                                                  </li>
                                                              ))}
                                                          </ul>
                                                      )
                                                    : hidden.info.data && (
                                                          <div className="sc-table-tooltip">{rowData}</div>
                                                      )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </td>
            )
        }

        if (isFullWidth) {
            return (
                <td key={keyIndex} colSpan={config.length} style={style}>
                    <div className="sc-table-second-hidden">
                        {fullWidthElement && row[fullWidthElement.key] && (
                            <p>
                                {fullWidthElement.prefix}
                                {fullWidthElement.arrayObjectKey
                                    ? row[fullWidthElement.key]
                                          .map(el => el[fullWidthElement.arrayObjectKey])
                                          .join(', ')
                                    : row[fullWidthElement.key]}
                            </p>
                        )}
                    </div>
                </td>
            )
        }

        return (
            <td key={key} style={style}>
                <div className="sc-table-display-data">
                    {icon && <i className={icon} />}
                    {statusesInfo || isDate ? (
                        <>{isDate && renderDate(isDate, row[key])}</>
                    ) : (
                        generateCellData(row, key)
                    )}
                </div>
            </td>
        )
    })
}

const TableContainer = ({ button = {}, head, body, maxHeight, className = '', emptyList = {}, removeCustomButton }) => {
    const [expandedId, setExpandedId] = useState(null)
    const [isExtraTbodyExpanded, setIsExtraTbodyExpanded] = useState(true)
    const { text, onClick } = button
    const colsWidthSum = head.reduce((acc, col) => acc + col[1], 0)
    const isData = body.data && body.data.length > 0
    const isExtraTbodyData = body.extraTbody && body.extraTbody.data && body.extraTbody.data.length > 0

    const handleToggleExpanded = id => {
        setExpandedId(id === expandedId ? null : id)
    }

    const renderBody = () => {
        const objectWithKey = key => body.config.find(el => el[key])
        const objectWithHiddenValue = objectWithKey('hidden')
        const objectWithFullWidthElement = objectWithKey('fullWidthElement')

        const toggleExtraTbody = () => {
            setIsExtraTbodyExpanded(!isExtraTbodyExpanded)
        }

        const renderExtraTbodyButton = tbody => (
            <tr className="sc-table-hidden-tbody-button">
                <td>
                    {tbody.data.length === 0 ? (
                        <Button text={tbody.noDataButtonText} className="sc-button sc-button-naked" isDisabled />
                    ) : (
                        <Button
                            text={isExtraTbodyExpanded ? tbody.hideButtonText : tbody.showButtonText}
                            className="sc-button sc-button-naked"
                            onClick={toggleExtraTbody}
                        />
                    )}
                </td>
            </tr>
        )

        const renderRows = data =>
            data.map((row, index) => {
                const isCreationState = [
                    powerStatesCodes.unknown,
                    powerStatesCodes.creating,
                    powerStatesCodes.failed,
                    null
                ].includes(row.status)

                return (
                    <Fragment key={index}>
                        <tr
                            className={`main-tr${
                                objectWithHiddenValue || objectWithFullWidthElement ? ' hasHiddenValue' : ''
                            }${row.id === expandedId ? ' isExpanded' : ''}${isCreationState ? ' isCreationState' : ''}`}
                            onClick={() => handleToggleExpanded(row.id)}
                        >
                            {renderBodyTd({ head, config: body.config, row })}
                        </tr>
                        {objectWithHiddenValue && !isCreationState && (
                            <tr>
                                {renderBodyTd({ head, config: body.config, row, isHidden: true, removeCustomButton })}
                            </tr>
                        )}
                        {objectWithFullWidthElement && (
                            <tr className="sc-table-second-hidden-tr">
                                {renderBodyTd({ head, config: body.config, row, isFullWidth: true })}
                            </tr>
                        )}
                    </Fragment>
                )
            })

        return (
            <>
                <tbody>{renderRows(body.data)}</tbody>
                {body.extraTbody && (
                    <tbody className={`sc-table-hidden-tbody${isExtraTbodyExpanded ? ' isExpanded' : ''}`}>
                        {renderExtraTbodyButton(body.extraTbody)}
                        {renderRows(body.extraTbody.data)}
                    </tbody>
                )}
            </>
        )
    }

    return (
        <div
            className={`sc-box sc-table-wrapper ${className}${maxHeight ? ' hasMaxHeight' : ''}`}
            style={{ maxHeight }}
        >
            {colsWidthSum !== 100 && 'Columns width sum is ' + colsWidthSum + ', should be 100'}
            {text && <Button text={text} onClick={onClick} />}
            {isData || isExtraTbodyData ? (
                <table className="sc-table">
                    {renderHead(head)}
                    {renderBody()}
                </table>
            ) : (
                <div className="sc-table-empty-list">
                    {emptyList.icon && <i className={emptyList.icon} />}
                    <h2>{emptyList.text}</h2>
                </div>
            )}
        </div>
    )
}

export default TableContainer
