import React from 'react'
import { useGet } from '@signpost/common/src/helpers/customHooks'
import { URLS } from '@signpost/common/src/helpers/api'
import Students from './Students'
import { BoxLayout, Status } from 'components'

const icon = 'fas fa-users'

const handleAddCredits = row => {} //WAITING FOR API

const StudentsContainer = ({ id }) => {
    const { isLoaded, error, data } = useGet(URLS.labs.labStudents(id))

    return (
        <BoxLayout icon={icon} title="Students" isEmptyList={!data || data.length === 0} isUnderlinedHeader>
            <Status isLoaded={isLoaded} error={error} isTableLoader>
                <Students icon={icon} data={data} handleAddCredits={handleAddCredits} />
            </Status>
        </BoxLayout>
    )
}

export default StudentsContainer
