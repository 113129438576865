import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import { AzureAD } from 'react-aad-msal'
import { authProvider } from '@signpost/common/src/helpers/api'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { theme } from './theme'

ReactDOM.render(
    <AzureAD provider={authProvider}>
        {({ login, logout, authenticationState }) => (
            <ThemeProvider theme={theme}>
                <App
                    login={login}
                    logout={params => {
                        logout(params)
                        localStorage.removeItem('schoolId')
                    }}
                    authenticationState={authenticationState}
                />
            </ThemeProvider>
        )}
    </AzureAD>,
    document.getElementById('root')
)

serviceWorker.unregister()
