import React from 'react'
import { Button, ProgressBar } from 'components'
import './BoxLayout.css'

const renderHeader = ({ isOnTop, isUnderlinedHeader, isShadowLineHeader, icon, title, handleGoBack }) => (
    <div
        className={`sc-box sc-indent-${isOnTop ? 'top' : 'middle'}${handleGoBack ? ' isButton' : ''}`}
        title="Hyper test Lab title"
    >
        {handleGoBack && <Button icon="fas fa-angle-left" isWhite onClick={handleGoBack} />}
        <div
            className={`sc-box-layout-title${isUnderlinedHeader ? ' isUnderlinedHeader' : ''}${
                isShadowLineHeader ? ' isShadowLineHeader' : ''
            }`}
        >
            <i className={icon} />
            <h2>{title}</h2>
        </div>
    </div>
)

const renderDescription = ({ label, progressBar, text, buttonText, onButtonClick }) => (
    <div className="sc-box">
        <div className="sc-box-layout-description">
            <div>
                <h2>
                    {label}
                    {progressBar && (
                        <span>
                            {progressBar.value && progressBar.value.toFixed(2)}
                            <span> of </span>
                            {progressBar.all}
                        </span>
                    )}
                </h2>
                <p>{text}</p>
            </div>
            {buttonText && <Button text={buttonText} onClick={onButtonClick} />}
        </div>
        {progressBar && <ProgressBar data={progressBar} />}
    </div>
)

const BoxLayout = ({
    style,
    isOnTop,
    isUnderlinedHeader,
    isShadowLineHeader,
    icon,
    title,
    description,
    children,
    handleGoBack
}) => (
    <div className="sc-box-layout" style={style}>
        {renderHeader({ isOnTop, isUnderlinedHeader, isShadowLineHeader, icon, title, handleGoBack })}
        {description && renderDescription(description)}
        {children}
    </div>
)

export default BoxLayout
