import React from 'react'
import './Textarea.css'

const Textarea = ({ label, name, id, placeholder, value, isRequired, secondLabel, onChange }) => (
    <div className="sc-textarea">
        {label && <label htmlFor={name}>{label}</label>}
        <textarea
            id={id || name}
            placeholder={placeholder}
            value={value}
            required={isRequired}
            onChange={e => onChange && onChange(e, name)}
            rows="5"
            cols="33"
        />
        {secondLabel && <label htmlFor={name}>{secondLabel}</label>}
    </div>
)

export default Textarea
