import React, { useState } from 'react'
import { post, URLS } from '@signpost/common/src/helpers/api'
import { handleShowNotification } from '@signpost/common/src/helpers/requestStatus'
import Credits from './Credits'

const CreditsContainer = ({ data, userId, isStudent }) => {
    const [isModal, setIsModal] = useState(false)
    const [isLoaded, setIsLoaded] = useState(true)
    const [error, setError] = useState(null)
    const [formValues, setFormValues] = useState({ message: '' })

    const handleToggleModal = action => {
        setIsModal(!!(action === 'show'))
    }

    const handleChange = (e, key) => {
        setFormValues({ ...formValues, [key]: e.target.value })
    }

    const handleSave = async () => {
        setIsLoaded(false)

        const url = URLS.requests.send
        const body = {
            userId,
            title: 'A student requests more credits',
            description: formValues.message,
            credits: 0
        }

        try {
            await post({ url, body })
            setIsModal(false)
            handleShowNotification('info')
        } catch (err) {
            setError({ data: { Message: 'Sending Error' } })
        }
        setIsLoaded(true)
    }

    const modalData = {
        isModal,
        isLoaded,
        isStudent,
        error,
        formValues,
        handleToggleModal,
        handleChange,
        handleSave
    }

    return <Credits data={data} error={error} modalData={modalData} />
}

export default CreditsContainer
