import React from "react";
import { Field } from "formik";
import { FormControl, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from "styled-components";

const Error = styled.p`
  color: #f44336
  font-size: 12px
  margin: 4px 0 0 0
`;

const CustomAutocomplete = ({
  field: { name, onChange, ...restFieldProps },
  form: { errors, setFieldValue },
  size = 'small',
  placeholder,
  ...props
}) => (
  <FormControl className={`sc-input-wrapper${errors[name] ? " isError" : ""}`}>
    <Autocomplete
      onChange={(_, value) => setFieldValue('ownerId', value)}
      {...restFieldProps}
      size={size}
      renderInput={(params) => <TextField name={name} placeholder={placeholder} {...params} variant="outlined" label="User" />}
      {...props}
    />
    <Error>{errors[name] || ''}</Error>
  </FormControl>
);

const AutocompleteFormControl = ({
  label,
  name,
  isDisabled,
  placeholder,
  options,
  getOptionLabel,
  renderOption,
}) => (
  <Field
    label={label}
    variant="outlined"
    name={name}
    disabled={isDisabled}
    loading={isDisabled}
    className={isDisabled ? 'isDisabled' : ''}
    component={CustomAutocomplete}
    placeholder={placeholder}
    options={options}
    getOptionLabel={getOptionLabel}
    renderOption={renderOption}
  />
);

export default AutocompleteFormControl;
