import { useState } from 'react'
import { API, get, URLS } from '@signpost/common/src/helpers/api'

export const useMe = () => {
    const [meData, setMeData] = useState()
    const [meError, setMeError] = useState(false)
    const [isMeLoaded, setIsMeLoaded] = useState(false)

    const fetchMe = async setCurrentSchoolId => {
        setIsMeLoaded(false)
        try {
            const { data: authData } = await get({ url: URLS.authorization })

            const { id, email, role, currentBalance, maxCredits, schoolId } = authData

            if (!localStorage.getItem('schoolId') && schoolId && role === 'SupportAdmin') {
                localStorage.setItem('schoolId', schoolId)
                setCurrentSchoolId(schoolId)
            }

            setMeData({
                id,
                email,
                role,
                currentBalance,
                maxCredits,
                schoolId
            })
        } catch (exception) {
            setMeError(exception)
        } finally {
            setIsMeLoaded(true)
        }
    }

    return { meData, isMeLoaded, meError, fetchMe }
}
