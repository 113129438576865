import React from "react";
import { Field } from "formik";
import { FormControl, TextField } from "@material-ui/core";
import styled from "styled-components";
import "./Input.css";

const Error = styled.p`
  color: #f44336
  font-size: 12px
  margin: 4px 0 0 0
`;

const Input = ({
  label,
  name,
  isDisabled,
  error,
  type,
  placeholder,
  isRequired,
  min = 1,
}) => (
  <FormControl className={`sc-input-wrapper${error ? " isError" : ""}`}>
    <Field
      label={label}
      variant="outlined"
      name={name}
      disabled={isDisabled}
      className={`sc-input${isDisabled ? " isDisabled" : ""}`}
      as={TextField}
      type={type}
      inputProps={{ min }}
      error={!!error}
      placeholder={placeholder}
      required={isRequired}
    />
    <Error>{error}</Error>
  </FormControl>
);

export default Input;
