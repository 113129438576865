import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8ec154',
    },
    secondary: {
      main: '#d4e157',
    },
  },
})
