import React from 'react'
import { Status } from 'components'
import './Modal.css'

const Layout = ({ isLoaded, error, title, children, minHeight, handleHide }) => (
    <div className="sc-modal-layout">
        <div className="sc-modal-background" />
        <div className="sc-modal-body-wrapper" role="document" style={{ minHeight }}>
            <div className="sc-modal-header">
                {title && <h5>{title}</h5>}
                <button type="button" className="close" onClick={handleHide}>
                    &times;
                </button>
            </div>
            <Status isLoaded={isLoaded} error={error}>
                <div className="sc-modal-layout-children">{children}</div>
            </Status>
        </div>
    </div>
)

export default Layout
