import React, { useState, useEffect } from 'react'
import { put, URLS } from '@signpost/common/src/helpers/api'
import Info from './Info'

const InfoContainer = ({ data, hasTeacherRights, handleChangeView }) => {
    const [isModal, setIsModal] = useState(false)
    const [isModalLoaded, setIsModalLoaded] = useState(true)
    const [error, setError] = useState('')
    const [description, setDescription] = useState('')
    const [updatedDescription, setUpdatedDescription] = useState('')

    useEffect(() => {
        setDescription(data.description)
    }, [data.description])

    const handleToggleModal = action => {
        setIsModal(action === 'show')
    }

    const handleChange = e => {
        setUpdatedDescription(e.target.value)
    }

    const handleSave = async () => {
        const url = URLS.labs.labDescription(data.id)
        const body = {
            labId: data.id,
            description: updatedDescription
        }
        setIsModalLoaded(false)

        try {
            await put({ url, body })
            setDescription(updatedDescription)
            setUpdatedDescription('')
            setIsModal(false)
        } catch (err) {
            setError(err)
        }
        setIsModalLoaded(true)
    }

    const modalData = {
        isModal,
        updatedDescription: updatedDescription || description,
        isModalLoaded,
        error,
        handleToggleModal,
        handleChange,
        handleSave
    }

    return (
        <Info
            name={data.name}
            description={description}
            modalData={modalData}
            hasTeacherRights={hasTeacherRights}
            handleChangeView={handleChangeView}
        />
    )
}

export default InfoContainer
