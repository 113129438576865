import React from "react";
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio as MaterialRadio,
} from "@material-ui/core";
import "./Radio.css";

const Radio = ({ label, name, value, values, isAllDisabled, onChange }) => {
  return (
    <div>
      <FormControl component="fieldset" className="sc-radio-wrapper">
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <RadioGroup
          name={name}
          value={value}
          onChange={onChange}
          className="sc-radio-group"
        >
          {values.map(({ value, label, isDisabled }) => (
            <FormControlLabel
              key={label}
              value={value}
              control={<MaterialRadio />}
              label={label}
              disabled={isAllDisabled || isDisabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default Radio;
