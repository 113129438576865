import React, { useState } from 'react'
import { SchoolSelect } from '@signpost/common/src/components'
import { LabsList, Lab } from 'pages'
import { Header, Status, Footer } from 'components'

const TeacherStudent = ({ globalState, handleLogout }) => {
    const { data: userInfo, error: userInfoError } = globalState?.me || {}
    const { data: schools, isLoaded: areSchoolsLoaded, error: schoolsError } = globalState?.schools || {}
    const [currentView, setCurrentView] = useState('labsList')
    const [currentLabId, setCurrentLabId] = useState(null)
    const isSupportAdmin = userInfo?.role === 'SupportAdmin'

    const handleChangeView = (view, id) => {
        if (id) {
            setCurrentLabId(id)
        }
        setCurrentView(view)
    }

    return (
        <>
            <div className="sc-main-layout">
                <Header userName={userInfo?.email} handleLogout={handleLogout} />
                <div className={`sc-main-layout-toolbar${isSupportAdmin ? '' : ' empty'}`}>
                    {!!userInfo && isSupportAdmin && areSchoolsLoaded && !schoolsError && (
                        <SchoolSelect
                            defaultValue={userInfo?.schoolId}
                            value={globalState.currentSchoolId}
                            setValue={globalState.setCurrentSchoolId}
                            schools={schools || []}
                            loading={!areSchoolsLoaded}
                        />
                    )}
                    {schoolsError && (
                        <div>Error: {schoolsError?.data?.Message || 'Schools have not been fetched.'}</div>
                    )}
                </div>
                <div className="sc-main-layout-body">
                    {currentView === 'labsList' && (
                        <Status isLoaded={!!userInfo && (areSchoolsLoaded || !isSupportAdmin)} error={userInfoError}>
                            <LabsList
                                role={userInfo?.role}
                                creditsData={{
                                    currentBalance: userInfo?.currentBalance,
                                    maxCredits: userInfo?.maxCredits
                                }}
                                userId={userInfo?.id}
                                handleChangeView={handleChangeView}
                            />
                        </Status>
                    )}
                    {currentView === 'lab' && (
                        <Lab
                            id={currentLabId}
                            role={userInfo?.role}
                            email={userInfo?.email}
                            handleChangeView={handleChangeView}
                        />
                    )}
                </div>
                <Footer />
            </div>
            <div id="sc-modal-root" />
        </>
    )
}

export default TeacherStudent
