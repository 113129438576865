import React, { useState, useCallback } from "react";
import { Input } from "components";
import { Button, Checkbox } from "@signpost/common/src/components";
import "./SearchBar.css";
import debounce from "lodash/debounce";

const SearchBar = ({
  label,
  placeholder,
  buttonText,
  chosen,
  chosenNameKey,
  checkboxes,
  isSubmittingEmptyListEnabled,
  onRemoveChosen,
  onButtonClick,
  debounceValue = 500,
  onChange,
  onSubmitChosen,
  submitButtonLabel = "Add To Group",
}) => {
  const [value, setValue] = useState("");
  const callback = useCallback(
    debounce((e) => onChange(e), debounceValue),
    []
  );

  const addDebounce = (e) => {
    const value = e.target.value;
    setValue(value);
    callback(value);
  };

  const removeButtonStyles = {
    cursor: "pointer",
    color: "red",
  };

  const renderChosen = () => {
    if (chosen.length > 0 || isSubmittingEmptyListEnabled) {
      return (
        <div className="sc-chosen-in-search">
          <h6>Chosen:</h6>
          <p>
            {chosen.map((el, index) => (
              <span key={index}>
                {el[chosenNameKey]}
                <span
                  onClick={() => onRemoveChosen(el)}
                  style={removeButtonStyles}
                >
                  {" "}
                  x
                </span>
                ,{" "}
              </span>
            ))}
          </p>
          <Button
            text={submitButtonLabel}
            onClick={() => onSubmitChosen(chosen)}
          />
        </div>
      );
    }
  };

  const renderCheckboxes = () => (
    <div className="sc-checkboxes-in-search">
      {checkboxes.map(
        ({ name, label, isChecked, isDisabled, handleChange }) => {
          return (
            <Checkbox
              key={name}
              name={name}
              label={label}
              isChecked={isChecked}
              isDisabled={isDisabled}
              handleChange={handleChange}
            />
          );
        }
      )}
    </div>
  );

  return (
    <div className="page-content">
      {buttonText && (
        <div className="form-group clearfix">
          <button
            type="button"
            onClick={onButtonClick}
            className="btn btn-action btn-sm float-right"
          >
            <i className="fas fa-plus" /> {buttonText}
          </button>
        </div>
      )}
      {checkboxes && renderCheckboxes()}
      <Input
        label={label}
        type="search"
        name="search"
        placeholder={placeholder}
        className="form-control-sm form-control"
        value={value}
        onChange={addDebounce}
      />
      {chosen && renderChosen()}
    </div>
  );
};

export default SearchBar;
