import React from 'react'
import './ProgressBar.css'

const ProgressBar = ({ data = {} }) => (
    <div className="sc-progress-bar">
        <div style={{ width: (data.value / data.all) * 100 + '%' }} />
    </div>
)

export default ProgressBar
