import React, { useState } from 'react'
import debounce from 'lodash/debounce'
import './Button.css'

const Button = ({
    className = 'sc-button',
    disabledTimeout,
    isLoading,
    isWhite,
    isDark,
    icon,
    text,
    extraText,
    isDisabled,
    onClick
}) => {
    const [isDisabledByTimeout, setIsDisabledByTimeout] = useState(false)

    const handleDisableTimeout = debounce(() => {
        setIsDisabledByTimeout(false)
    }, disabledTimeout)

    const handleOnClick = e => {
        if (disabledTimeout) {
            onClick(e)
            setIsDisabledByTimeout(true)
            return handleDisableTimeout()
        }
        return onClick(e)
    }

    return (
        <button
            type="button"
            className={`${className}
        ${isDisabled || isLoading || isDisabledByTimeout ? ' isDisabled' : ''}${isLoading ? ' isLoading' : ''}${
                isWhite ? ' isWhite' : ''
            }${isDark ? ' isDark' : ''}`}
            disabled={isDisabled}
            onClick={handleOnClick}
        >
            {extraText && <p className="extra-text">{extraText}</p>}
            {(isLoading || isDisabledByTimeout) && <div className="sc-loader" />}
            <span>
                {icon && <i className={icon} />} {text}
            </span>
        </button>
    )
}

export default Button
