import React, { useState } from "react";
import debounce from "lodash/debounce";
import "./Button.css";

const Button = ({
  className = "sc-button",
  style,
  disabledTimeout,
  isLoading,
  isWhite,
  isDark,
  isDanger,
  icon,
  text,
  type = "button",
  isDisabled,
  onClick = () => {},
  onReset
}) => {
  const [isDisabledByTimeout, setIsDisabledByTimeout] = useState(false);

  const handleDisableTimeout = debounce(() => {
    setIsDisabledByTimeout(false);
  }, disabledTimeout);

  const handleOnClick = e => {
    if (disabledTimeout) {
      onClick(e);
      setIsDisabledByTimeout(true);
      return handleDisableTimeout();
    }
    return onClick(e);
  };

  return (
    <>
      <button
        type={type}
        className={`${className}
    ${isDisabled || isLoading || isDisabledByTimeout ? " isDisabled" : ""}${
          isLoading ? " isLoading" : ""
        }${isWhite ? " isWhite" : ""}${isDark ? " isDark" : ""}${
          isDanger ? " isDanger" : ""
        }`}
        disabled={isDisabled}
        onClick={handleOnClick}
        style={style}
      >
        {(isLoading || isDisabledByTimeout) && <div className="sc-loader" />}
        <span>
          {icon && <i className={icon} />} {text}
        </span>
      </button>
      {onReset && (
        <div className="sc-reset-button" onClick={() => onReset("reset")}>
          <i className="fas fa-times" />
        </div>
      )}
    </>
  );
};

export default Button;
