import React from 'react'
import { Table, Status } from 'components'
import { Modal, Confirm } from 'components/Modal'
import {
    Layout,
    Form,
    Input,
    Radio,
    SearchBar,
    Table as CommonTable,
    FormInput,
    FormFooter,
    FormRadioGroup,
    FormSelect
} from '@signpost/common/src/components'
import { Formik, Form as FormikForm } from 'formik'
import { put, URLS } from '@signpost/common/src/helpers/api'
import { returnOnlyNotDuplicated } from '@signpost/common/src/helpers/object'
import {
    powerStatesCodes,
    powerStatesUserCodes,
    powerStatesCommands,
    osType,
    vmButtonsTypes
} from '@signpost/common/src/const'
import { infoToast, errorToast } from '@signpost/common/src/helpers/requestStatus'
import {
    VMClaimModal as config,
    VMClaimModalKeys,
    VMPortsModal as portsConfig,
    VMPortsModalKeys,
    DNSModal as dnsConfig,
    DNSModalKeys
} from '@signpost/common/src/formsConfig'
import styled from 'styled-components'
const SearchWrapper = styled.div`
    button {
        float: right;
    }
    .page-content {
        &:last-child {
            margin: 80px 0 20px;
        }
    }
    td {
        cursor: pointer;
    }
`
export const PowerStateContext = React.createContext(powerStatesUserCodes.processing)

const VirtualMachines = ({
    icon,
    data,
    role,
    email,
    modalRdpProps,
    modalEditIPProps,
    modalEditContributors,
    modalEditDNSProps,
    handleConnectVM,
    handleGetNetworkSecurityGroup
}) => {
    const handleVMImageAction = async (row, actionType) => {
        const url = URLS.virtualMachines.action(row.id, actionType)
        try {
            infoToast(`Command '${actionType}' was sent to VM!`)
            await put({ url })
        } catch (err) {
            errorToast('Command failed!')
        }
    }

    const { modalData, modalError, isModal, isModalLoaded, handleToggleModal, handleDownloadRdpFile } = modalRdpProps
    const { userName, password, host, port, operatingSystem } = modalData
    const {
        iPv4AddressType,
        iPv6AddressType,
        iPv4Address,
        iPv6Address,
        isEditIPModalOpen,
        hasIPv6AddressSpace,
        handleToggleEditIPModal,
        handleEditIP,
        handleChangeRadio,
        handleEditPort,
        handleDeletePort,
        areSecurityRulesLoaded,
        securityRules,
        hasNetworkSecurityGroup,
        isEditPortLoaded,
        isIpLoaded
    } = modalEditIPProps

    const { DNSData, areDNSLoaded, handleDeleteDNS, handleEditDNS } = modalEditDNSProps

    const {
        isEditContributorsModalOpen,
        chosenContributors,
        potentialContributors,
        arePotentialContributorsLoaded,
        potentialContributorsError,
        handleGetPotentialContributors,
        handleSumbitContributors,
        handleToggleContributorsModal,
        onRemoveChosen,
        onContributorsRowClick
    } = modalEditContributors

    const imageButtonConfig = {
        key: 'status',
        type: vmButtonsTypes.power,
        [powerStatesCodes.deallocated]: {
            text: powerStatesCommands.start,
            onClick: row => handleVMImageAction(row, 'Start')
        },
        [powerStatesCodes.running]: {
            text: powerStatesCommands.stop,
            onClick: row => handleVMImageAction(row, 'Deallocate')
        },
        [powerStatesCodes.starting]: {
            text: powerStatesUserCodes.starting,
            isDisabled: true
        },
        [powerStatesCodes.deallocating]: {
            text: powerStatesUserCodes.deallocating,
            isDisabled: true
        },
        [powerStatesCodes.stopping]: {
            text: powerStatesUserCodes.stopping,
            isDisabled: true
        },
        [powerStatesCodes.stopped]: {
            text: powerStatesUserCodes.stopped,
            isDisabled: true
        },
        default: {
            text: powerStatesUserCodes.processing,
            isDisabled: true
        }
    }

    const tableConfig = [
        {
            key: 'name',
            icon,
            hidden: {
                info: {
                    prefix: 'Host: ',
                    key: 'host'
                }
            },
            fullWidthElement: {
                prefix: 'Shared width: ',
                key: 'shared',
                arrayObjectKey: 'email'
            }
        },
        { key: 'operatingSystem' },
        { key: 'coreCount' },
        { key: 'memoryCount' },
        {
            key: 'owner',
            hidden: {
                info: {
                    prefix: 'contributors: ',
                    key: 'contributors',
                    showLength: true,
                    tooltip: {
                        isListStyle: true,
                        key: 'email'
                    }
                }
            }
        },
        { key: 'ownerFullName' },
        {
            key: 'imageName',
            hidden: {
                button: imageButtonConfig,
                secondButton: {
                    type: 'custom',
                    text: 'Edit',
                    onClick: handleToggleEditIPModal
                },
                thirdButton: {
                    type: 'custom',
                    text: 'Share',
                    onClick: handleToggleContributorsModal
                }
            }
        },
        {
            key: 'status',
            hidden: {
                button: {
                    type: vmButtonsTypes.connect,
                    onClick: handleConnectVM
                },
                secondButton: ['Teacher', 'SupportAdmin'].includes(role) && {
                    type: 'custom',
                    text: 'Add NSG',
                    onClick: handleGetNetworkSecurityGroup,
                    hideCondition: 'hasNetworkSecurityGroup',
                    extraText: 'Add NSG before Connect'
                }
            }
        }
    ]

    const radioValues = [
        { value: 'Dynamic', label: 'dynamic' },
        { value: 'Static', label: 'static' }
    ]

    const isWindows = operatingSystem === osType.windows
    const isLinux = operatingSystem === osType.linux
    const linuxButtonText = isLinux && 'Close'
    const contributorsTableHead = [
        { name: 'Email', bodyKey: 'email', width: 50 },
        { name: 'Full Name', bodyKey: 'fullName', width: 50 }
    ]
    const filteredListIsNotEmpty =
        potentialContributors && returnOnlyNotDuplicated(potentialContributors, chosenContributors).length > 0

    const securityRulesTableHead = [
        { name: 'Ports', bodyKey: 'ports', width: 40 },
        { name: 'Type', bodyKey: 'type', width: 25 },
        { name: 'Protocol', bodyKey: 'protocol', width: 20 },
        { name: '', bodyKey: 'customActions', width: 15 }
    ]

    const dnsTableHead = [
        {
            name: 'Address',
            bodyKey: 'address',
            width: 85
        },
        { name: '', bodyKey: 'customActions', width: 15 }
    ]

    const handleRemoveCustomButton = (row, button) => {
        return !(role === 'Student' && row.owner !== email && button.text === 'Share')
    }

    const portsRadioGroupConfig = [
        {
            value: 'inbound',
            label: 'Inbound'
        },
        {
            value: 'outbound',
            label: 'Outbound'
        }
    ]

    const portsSelectConfig = [
        {
            value: '*',
            label: 'Any'
        },
        {
            value: 'tcp',
            label: 'Tcp'
        },
        {
            value: 'udp',
            label: 'Udp'
        },
        {
            value: 'icmp',
            label: 'Icmp'
        }
    ]

    const portsTableActions = [{ action: handleDeletePort, text: 'Delete' }]
    const dnsTableActions = [{ action: handleDeleteDNS, text: 'Delete' }]

    return (
        <>
            <Table
                head={[
                    ['Name', 12],
                    ['OS', 8],
                    ['CPU', 5],
                    ['RAM', 5],
                    ['Claimed', 13],
                    ['Full Name', 14],
                    ['Image', 23],
                    ['Status', 20]
                ]}
                body={{
                    data,
                    config: tableConfig
                }}
                emptyList={{ icon, text: 'Lab does not contain any virtual machines.' }}
                removeCustomButton={handleRemoveCustomButton}
            />
            {isModal && (
                <Modal>
                    <Confirm
                        title={`${isLinux ? 'Linux' : ''}${isWindows ? 'Windows' : ''} Connection Params`}
                        isLoaded={isModalLoaded}
                        error={modalError}
                        handleHide={handleToggleModal}
                        oneButtonText={linuxButtonText}
                        confirmButtonText="Download RDP file"
                        handleConfirm={handleDownloadRdpFile}
                    >
                        <div>User Name: {userName}</div>
                        <div>Password: {password}</div>
                        <div>Host: {host}</div>
                        {isLinux && <div>Port: {port} </div>}
                    </Confirm>
                </Modal>
            )}
            {isEditIPModalOpen && (
                <Modal>
                    <Layout
                        isLoaded={true}
                        tabsConfig={[
                            { text: 'Set IPv4 and IPv6 addresses' },
                            { text: 'Set Port' },
                            { text: 'Set DNS' }
                        ]}
                        handleHide={handleToggleEditIPModal}
                        minHeight={488}
                    >
                        {isIpLoaded ? (
                            <Form
                                initialValues={{ iPv4Address, iPv6Address }}
                                validationSchema={config.validationSchema}
                                handleHide={handleToggleEditIPModal}
                                handleSave={handleEditIP}
                            >
                                <p>If no address is filled, it will be assigned dynamically.</p>
                                <Radio
                                    label="IPv4 Address"
                                    name="iPv4"
                                    value={iPv4AddressType}
                                    values={radioValues}
                                    onChange={handleChangeRadio}
                                />
                                <Input
                                    name={VMClaimModalKeys.iPv4}
                                    isDisabled={iPv4AddressType === 'Dynamic'}
                                    placeholder={`e.g. ${iPv4AddressType === 'Dynamic' ? '10.0.16.2' : '10.0.0.2'}`}
                                />
                                <Radio
                                    label="IPv6 Address"
                                    name="iPv6"
                                    value={iPv6AddressType}
                                    values={radioValues}
                                    isAllDisabled={!hasIPv6AddressSpace}
                                    onChange={handleChangeRadio}
                                />
                                <Input
                                    name={VMClaimModalKeys.iPv6}
                                    isDisabled={!hasIPv6AddressSpace || iPv6AddressType === 'Dynamic'}
                                    placeholder={`e.g. ${
                                        iPv6AddressType === 'Dynamic' ? 'ace:cab:dca:deee::4' : 'ace:cab:dca:deed::4'
                                    }`}
                                />
                            </Form>
                        ) : (
                            <Status isLoaded={false} />
                        )}

                        {hasNetworkSecurityGroup ? (
                            <Status isLoaded={isEditPortLoaded}>
                                <Formik
                                    validateOnChange={true}
                                    initialValues={{ ...portsConfig.initialValues }}
                                    validationSchema={portsConfig.validationSchema}
                                    onSubmit={async (data, { setSubmitting }) => {
                                        setSubmitting(true)
                                        await handleEditPort(data)
                                        setSubmitting(false)
                                    }}
                                >
                                    {() => (
                                        <FormikForm>
                                            <FormInput
                                                name={VMPortsModalKeys.ports}
                                                label="Ports (range 0-65535, comma separated, no spaces)"
                                                placeholder="8888,999"
                                            />
                                            <FormRadioGroup
                                                name={VMPortsModalKeys.type}
                                                config={portsRadioGroupConfig}
                                                groupLabel="Type"
                                            />
                                            <FormSelect
                                                name={VMPortsModalKeys.protocol}
                                                config={portsSelectConfig}
                                                label="Protocol"
                                            />
                                            <FormFooter onClose={handleToggleEditIPModal} />
                                            <Status isLoaded={areSecurityRulesLoaded}>
                                                <CommonTable
                                                    head={securityRulesTableHead}
                                                    body={securityRules}
                                                    isBackground
                                                    actions={portsTableActions}
                                                />
                                            </Status>
                                        </FormikForm>
                                    )}
                                </Formik>
                            </Status>
                        ) : (
                            <p>Waiting for creating Network Security Group..</p>
                        )}
                        <Formik
                            validateOnChange={true}
                            initialValues={dnsConfig.initialValues}
                            validationSchema={dnsConfig.validationSchema}
                            onSubmit={async (data, { setSubmitting }) => {
                                setSubmitting(true)
                                await handleEditDNS(data)
                                setSubmitting(false)
                            }}
                        >
                            {() => (
                                <FormikForm>
                                    <FormInput name={DNSModalKeys.address} label="Address" placeholder="" />
                                    <FormFooter onClose={handleToggleEditIPModal} />
                                    <Status isLoaded={areDNSLoaded}>
                                        <CommonTable
                                            head={dnsTableHead}
                                            body={DNSData}
                                            isBackground
                                            actions={dnsTableActions}
                                        />
                                    </Status>
                                </FormikForm>
                            )}
                        </Formik>
                    </Layout>
                </Modal>
            )}
            {isEditContributorsModalOpen && (
                <Modal>
                    <Layout isLoaded={true} title="Choose contributors" handleHide={handleToggleContributorsModal}>
                        <SearchWrapper>
                            <SearchBar
                                label="Find User By Email"
                                placeholder="min. 3 characters"
                                chosen={chosenContributors}
                                chosenNameKey="email"
                                isSubmittingEmptyListEnabled
                                onRemoveChosen={onRemoveChosen}
                                onChange={handleGetPotentialContributors}
                                onSubmitChosen={handleSumbitContributors}
                                submitButtonLabel="Submit"
                            />
                            <Status isLoaded={arePotentialContributorsLoaded} error={potentialContributorsError}>
                                {filteredListIsNotEmpty ? (
                                    <CommonTable
                                        title="Potential Contributors"
                                        head={contributorsTableHead}
                                        body={returnOnlyNotDuplicated(potentialContributors, chosenContributors)}
                                        isBackground
                                        onRowClick={onContributorsRowClick}
                                    />
                                ) : (
                                    <div className="page-content">
                                        {potentialContributors
                                            ? 'No potential contributors found'
                                            : 'Use search to find potential contributors '}
                                    </div>
                                )}
                            </Status>
                        </SearchWrapper>
                    </Layout>
                </Modal>
            )}
        </>
    )
}

export default VirtualMachines
