import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Menu,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button } from "@signpost/common/src/components";

const useStyles = makeStyles((theme) => {
  return {
    actionsIcon: {
      color: "#6c757d",
      textAlign: "right",
      cursor: "pointer",
    },
    emptyListInfo: {
      marginTop: '12px',
      textAlign: 'center'
    }
  };
});

const TableWrapper = ({ title, head, body, paginationProps, onRowClick, actions }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const toggleActions = (e) => {
    const wasActionsButtonClicked = e.currentTarget.className.includes(
      "sc-table-actions"
    );
    if (wasActionsButtonClicked) {
      return setAnchorEl(e.currentTarget);
    }
    return setAnchorEl(null);
  };

  const rowKeys = head.map((col) => col.bodyKey);
  const {
    page = 0,
    count = 0,
    rowsPerPage = 10,
    onChangeRowsPerPage = () => {},
    onChangePage = () => {},
  } = paginationProps || {};

  const renderHead = () => (
    <TableHead>
      <TableRow>
        {head.map(({ name, bodyKey, width }) => (
          <TableCell key={bodyKey} width={width + "%"}>
            {name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderBooleanValue = (value) =>
    value ? (
      <i className="fas fa-plus" style={{ color: "#8ec154" }}></i>
    ) : (
      <i className="fas fa-minus" style={{ color: "#dc3545" }}></i>
    );

  const renderBody = () => {
    return (
      <TableBody>
        {(body || []).map((row, rowIndex) => {
          return (
            <TableRow
              key={rowIndex}
              onClick={onRowClick && ((e) => onRowClick(row, e))}
            >
              {rowKeys.map((key, index) => {
                if (key === "customButton") {
                  return (
                    <TableCell key={index}>
                      <Button
                        isDanger={head[index].isDanger}
                        text={head[index].text}
                      />
                    </TableCell>
                  );
                }

                if (key === "customActions") {
                  return (
                    <TableCell key={index}>
                      <div
                        className={`${classes.actionsIcon} sc-table-actions`}
                        onClick={toggleActions}
                      >
                        <MoreVertIcon />
                      </div>
                      <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={toggleActions}
                      >
                        {actions.map(({ action, text }, index) => (
                          <MenuItem
                            key={rowIndex + "-" + index}
                            onClick={(e) => action(row, e)}
                          >
                            {text}
                          </MenuItem>
                        ))}
                      </Menu>
                    </TableCell>
                  );
                }

                return (
                  <TableCell key={index}>
                    {typeof row[key] === "boolean" || !row[key]
                      ? renderBooleanValue(row[key])
                      : row[key]}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const renderPagination = () => (
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );

  const renderIncorrectColsWidthWarning = () => {
    const colsWidthSum = head.reduce((acc, col) => acc + col.width, 0);
    const sumIsCorrect = colsWidthSum === 100;

    return sumIsCorrect ? null : (
      <div className="warning">
        {`Table columns width sum is ${colsWidthSum}%, should be 100%`}
      </div>
    );
  };

  const renderTitle = <p>{title}</p>;

  return (
    <>
      {renderIncorrectColsWidthWarning()}
      {title && renderTitle}
      <TableContainer>
        <Table>
          {renderHead()}
          {renderBody()}
        </Table>
        {body?.length === 0 && <p className={classes.emptyListInfo}>Empty list</p>}
      </TableContainer>
      {paginationProps && renderPagination()}
    </>
  );
};

export default TableWrapper;
