import React from 'react'
import { BoxLayout, Status, Table } from 'components'

const icon = 'fas fa-comment-dots'

const Messages = ({ isLoaded, error, data, handleShowAddCreditsModal, handleShowRejectModal }) => {
    const config = [
        {
            key: 'title',
            fullWidthElement: {
                key: 'description'
            }
        },
        { key: 'userEmail' },
        { key: 'userFullName' },
        { key: 'createTimeStamp', isDate: true },
        {
            key: 'customKey',
            buttons: [
                { text: 'Add credits', onClick: handleShowAddCreditsModal },
                { text: 'Reject', className: 'sc-button sc-button-red', onClick: handleShowRejectModal }
            ],
            style: { textAlign: 'center' }
        }
    ]

    return (
        <BoxLayout icon={icon} title="Messages" style={{ marginBottom: 40 }}>
            <Status error={error} isLoaded={isLoaded} isTableLoader>
                <Table
                    head={[
                        ['Subject', 25],
                        ['From', 26],
                        ['Full Name', 20],
                        ['Timestamp', 14],
                        ['', 15]
                    ]}
                    body={{
                        data: data.filter(message => !message.isHandled),
                        extraTbody: {
                            noDataButtonText: 'No archived messages',
                            showButtonText: 'Show archived messages',
                            hideButtonText: 'Hide archived messages',
                            data: data.filter(message => message.isHandled)
                        },
                        config
                    }}
                    emptyList={{ icon, text: 'No messages' }}
                    maxHeight={300}
                />
            </Status>
        </BoxLayout>
    )
}

export default Messages
