import { Box } from '@material-ui/core'
import React from 'react'
import { useGet } from '@signpost/common/src/helpers/customHooks'
import { Status, BoxLayout, Grid, Card } from 'components'
import { Messages, Credits } from './components'
import { URLS } from '@signpost/common/src/helpers/api'

const LabsList = ({ userId, role, creditsData, handleChangeView }) => {
    const { isLoaded, error, data } = useGet(URLS.labs.all)
    const isTeacher = role === 'Teacher'
    const isStudent = role === 'Student'
    const hasTeacherRights = ['Teacher', 'SupportAdmin'].includes(role)
    const config = hasTeacherRights
        ? [
              { label: 'Lab:', value: 'name' },
              { label: 'VMs:', value: 'amountOfVirtualMachines' },
              { label: 'Students:', value: 'amountOfStudents' },
              { label: 'Status:', value: 'isPublished', boolValues: ['Active', 'Not Active'] }
          ]
        : [
              { value: 'name', isBold: true },
              { value: 'amountOfVirtualMachines', suffix: 'VMs' }
          ]

    const renderCards = () => (
        <>
            {data?.length > 0 ? (
                data.map(lab => (
                    <Card
                        key={lab.id}
                        config={config}
                        data={lab}
                        isSimpleView={!hasTeacherRights}
                        handleChangeView={handleChangeView}
                    />
                ))
            ) : (
                <div className="sc-no-data-message">No lab</div>
            )}
        </>
    )

    return (
        <Status isLoaded={isLoaded} error={error}>
            <Credits data={creditsData} userId={userId} isStudent={isStudent} />
            {hasTeacherRights ? (
                <>
                    {isTeacher ? <Messages /> : <Box height="24px" />}
                    {renderCards()}
                </>
            ) : (
                <BoxLayout icon="fas fa-flask" title="Your Labs" isUnderlinedHeader={isTeacher}>
                    <div className="sc-box sc-indent-bottom sc-no-padding">
                        <div className="sc-box-overflow">
                            <Grid>{renderCards()}</Grid>
                        </div>
                    </div>
                </BoxLayout>
            )}
        </Status>
    )
}

export default LabsList
