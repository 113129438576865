import { HubConnectionBuilder } from '@aspnet/signalr'
import { addQueryToUrl, BASE_URL, URLS, authProvider } from '@signpost/common/src/helpers/api'

export const getConnection = async () => {
    let url = `${BASE_URL}${URLS.notificationHub}`
    const schoolId = localStorage.getItem('schoolId')
    if (schoolId) {
        url = addQueryToUrl(url, { schoolId })
    }

    const {
        idToken: { rawIdToken: accessToken }
    } = await authProvider.getIdToken()
    const connection = new HubConnectionBuilder().withUrl(url, { accessTokenFactory: () => accessToken }).build()

    const signalrStart = async () => {
        try {
            await connection.start()
        } catch (err) {
            console.log('Error while establishing connection => ' + err)
        }
    }
    return { connection, signalrStart }
}

export const signalrConst = {
    VirtualMachineStateChangedNotification: 'VirtualMachineStateChangedNotification',
    LackOfCreditsNotification: 'LackOfCreditsNotification',
    StoppingVirtualMachines: 'StoppingVirtualMachines',
    VirtualMachineCreationFailedNotification: 'VirtualMachineCreationFailedNotification',
    LowCreditsAlert: 'LowCreditsAlert'
}
