import React from 'react'
import { Table } from 'components'

const Students = ({ icon, data, handleAddCredits }) => (
    <>
        {data && (
            <Table
                head={[
                    ['Email', 25],
                    ['Full Name', 25],
                    ['Credits', 15],
                    ['Claimed VM', 15],
                    ['Last Access', 20]
                ]}
                body={{
                    data,
                    config: [
                        { key: 'email' },
                        {
                            key: 'fullName',
                            icon: 'fas fa-user'
                            // hidden: {                                 temp disabled
                            //     button: {
                            //         text: 'Add Credits',
                            //         onClick: handleAddCredits
                            //     }
                            // }
                        },
                        { key: 'amountOfCredits' },
                        { key: 'amountOfVirtualMachines' },
                        { key: 'lastAccess', isDate: true }
                    ]
                }}
                emptyList={{ icon, text: 'Lab does not contain any students' }}
            />
        )}
    </>
)

export default Students
