import React from 'react'
import { Button } from 'components'
import Layout from './MainLayout'
import './Modal.css'

const Form = ({ isLoaded, error, title, children, minHeight, handleHide, handleSave }) => (
    <Layout isLoaded={isLoaded} error={error} title={title} minHeight={minHeight} handleHide={handleHide}>
        <form>
            {children}
            <div className="sc-modal-footer">
                <Button text="Close" isDark onClick={handleHide} />
                <Button text="Save" onClick={handleSave} />
            </div>
        </form>
    </Layout>
)

export default Form
