import React from "react";
import { Field } from "formik";
import {
  Select as MaterialSelect,
  MenuItem,
  InputLabel,
  Input,
  FormControl,
  Chip
} from "@material-ui/core";
import styled from "styled-components";
import "./Select.css";

const ErrorP = styled.p`
  color: #f44336
  font-size: 12px
  margin: 4px 0 0 0
`;

const Select = ({ values, name, label, error, isMultipleChoice }) => {
  const renderChosenName = selectedId => {
    return values.filter(value => value.id === selectedId)[0].name;
  };

  const multipleChoiceProps = isMultipleChoice
    ? {
        multiple: true,
        labelId: name,
        input: <Input />,
        className: "sc-multiple-select",
        renderValue: selected => (
          <div>
            {selected.map(selectedId => (
              <Chip key={selectedId} label={renderChosenName(selectedId)} />
            ))}
          </div>
        )
      }
    : {};

  return (
    <div>
      <FormControl
        variant="outlined"
        className={`sc-select-wrapper${error ? " isError" : ""}`}
      >
        {values && (
          <>
            {label && <InputLabel id={name}>{label}</InputLabel>}
            <Field
              label={label}
              labelid={name}
              name={name}
              as={MaterialSelect}
              error={!!error}
              className="sc-select"
              {...multipleChoiceProps}
            >
              {values.map(value => (
                <MenuItem key={value.id} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Field>
          </>
        )}
        <ErrorP>{error}</ErrorP>
      </FormControl>
    </div>
  );
};

export default Select;
