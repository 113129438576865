import { Box } from '@material-ui/core'
import React, { useState } from 'react'
import { get, URLS } from '@signpost/common/src/helpers/api'

const getOptionLabel = (owners) => (ownerId) => 
    owners?.find((owner) => owner.id === ownerId)?.fullName || ''

const renderOption = (owners) => (ownerId) => {
    const currentOwner = owners?.find((owner) => owner.id === ownerId)
    return (
        <div>
            <div>{currentOwner?.fullName || ''}</div>
            <Box fontSize="0.8em" color="">{currentOwner?.email || ''}</Box>
        </div>
    )
}

const getOptions = (owners) => owners.map((owner) => owner.id)

export const usePotentialOwners = () => {
    const [potentialOwnersData, setPotentialOwnersData] = useState([])
    const [arePotentialOwnersLoaded, setArePotentialOwnersLoaded] = useState(true)
    const [potentialOwnersError, setPotentialOwnersError] = useState(false)

    const fetchPotentialOwners = async (labId) => {
        const url = URLS.labs.potentialVMOwners(labId)
        setArePotentialOwnersLoaded(false)
    
        try {
            const result = await get({ url })
            setPotentialOwnersData(result.data)
        } catch (err) {
            setPotentialOwnersError(err)
        } finally {
            setArePotentialOwnersLoaded(true)
        }
    }

    return {
      potentialOwnersData,
      arePotentialOwnersLoaded,
      potentialOwnersError,
      fetchPotentialOwners,
      getPotentialOwnersOptionLabel: getOptionLabel(potentialOwnersData),
      potentialOwnersOptions: getOptions(potentialOwnersData),
      renderPotentialOwnersOption: renderOption(potentialOwnersData),
    }
}
