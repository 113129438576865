import React, { useState } from "react";
import { Button } from "../../components";
import Layout from "./Layout";
import { Formik, Form } from "formik";
import "./Modal.css";

const FormContainer = ({
  isNoLayout,
  isLoaded,
  error,
  title,
  validationSchema,
  initialValues,
  children,
  minHeight,
  handleHide,
  handleSave,
}) => {
  const [wasSendAttempt, setWasSendAttempt] = useState(false);
  const addErrorToFieldProps = (field, errors) => {
    const fieldName = field.props.name;
    const isError = Object.keys(errors).includes(fieldName);

    return {
      ...field,
      props: {
        ...field.props,
        error: isError && wasSendAttempt ? errors[fieldName] : "",
      },
    };
  };

  const renderFormik = () => (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true);
        await handleSave(data);
        setSubmitting(false);
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          {Array.isArray(children)
            ? children.map((el) => addErrorToFieldProps(el, errors))
            : [children].filter(Boolean).map((el) => addErrorToFieldProps(el, errors))}
          <div className="sc-modal-footer">
            <Button
              text="Close"
              isDark
              onClick={handleHide}
              isDisabled={isSubmitting}
              type="button"
            />
            <Button
              text="Save"
              type="submit"
              isDisabled={isSubmitting}
              onClick={() => setWasSendAttempt(true)}
            />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      {isNoLayout ? (
        renderFormik()
      ) : (
        <Layout
          isLoaded={isLoaded}
          error={error}
          title={title}
          minHeight={minHeight}
          handleHide={handleHide}
        >
          {renderFormik()}
        </Layout>
      )}
    </>
  );
};

export default FormContainer;
