import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "../../Button";

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      textAlign: "right",
      "& button:first-child": {
        padding: "7px 11px",
        marginRight: "8px",
      },
    },
  };
});

const FooterContainer = ({ isDisabled, onClose, confirmText = "Save" }) => {
  const classes = useStyles();

  return (
    <footer className={classes.wrapper}>
      <Button isDark isDisabled={isDisabled} text="Close" onClick={onClose} />
      <Button isDisabled={isDisabled} text={confirmText} type="submit" />
    </footer>
  );
};

export default FooterContainer;
