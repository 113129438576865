import React from 'react'
import './Grid.css'

const Grid = ({ children, marginRight = 20 }) => (
    <div className="sc-flex-grid-wrapper">
        <div className="sc-flex-grid" style={{ width: `calc(100% + ${marginRight}px)` }}>
            {children}
            {Array.from(Array(10), (_, i) => (
                <div key={i} className="sc-grid-empty-box" style={{ marginRight }} />
            ))}
        </div>
    </div>
)

export default Grid
