import * as yup from "yup";

export const VMTModalKeys = {
  template: "templateName",
  size: "sizeId",
  image: "galleryImageId",
};
export const VMTModal = {
  initialValues: {
    [VMTModalKeys.template]: "",
    [VMTModalKeys.size]: "",
    [VMTModalKeys.image]: "",
  },
  validationSchema: yup.object({
    [VMTModalKeys.template]: yup.string().required("Add Template Name"),
    [VMTModalKeys.size]: yup.string().required("Choose Size"),
    [VMTModalKeys.image]: yup.string().required("Choose Gallery Image"),
  }),
};

const createLabModalKeys = {
  name: "name",
  groupsIds: "groupsIds",
  teachersIds: "teachersIds",
  regionId: "regionId",
  virtualNetworkId: "virtualNetworkId",
  maxVirtualMachinesPerStudent: "maxVirtualMachinesPerStudent",
  maxVirtualMachinesPerLab: "maxVirtualMachinesPerLab",
  hasIPv6AddressSpace: "hasIPv6AddressSpace",
};
export const createLabModal = {
  initialValues: {
    [createLabModalKeys.name]: "",
    [createLabModalKeys.groupsIds]: [],
    [createLabModalKeys.teachersIds]: [],
    [createLabModalKeys.regionId]: "",
    [createLabModalKeys.virtualNetworkId]: null,
    [createLabModalKeys.maxVirtualMachinesPerStudent]: 1,
    [createLabModalKeys.maxVirtualMachinesPerLab]: 1,
    [createLabModalKeys.hasIPv6AddressSpace]: false,
  },
  validationSchema: yup.object({
    [createLabModalKeys.name]: yup.string().required("Add Name"),
    [createLabModalKeys.groupsIds]: yup
      .array()
      .required("Select at least one group")
      .min(1),
    [createLabModalKeys.teachersIds]: yup
      .array()
      .required("Select at least one teacher")
      .min(1),
    [createLabModalKeys.regionId]: yup.string().required("Select one region"),
  }),
};

export const labNameKeys = {
  name: "name",
};
export const labName = {
  initialValues: {
    [labNameKeys.name]: "",
  },
  validationSchema: yup.object({
    [labNameKeys.name]: yup.string().required("Add Lab Name"),
  }),
};

export const userModalKeys = {
  isAdmin: "isAdmin",
  isTeacher: "isTeacher",
};
export const userModal = {
  initialValues: {
    [userModalKeys.isAdmin]: false,
    [userModalKeys.isTeacher]: false,
  },
  validationSchema: yup.object({}),
};
//student-teacher
export const VMClaimModalKeys = {
  ownerId: "ownerId",
  iPv4: "iPv4Address",
  iPv6: "iPv6Address",
  iPv4AddressType: "iPv4AddressType",
  iPv6AddressType: "iPv6AddressType",
};
const vmClaimModalValidationBase = {
  [VMClaimModalKeys.iPv4]: yup
    .string()
    .matches(
      /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
      "Incorrect format"
    ),
  [VMClaimModalKeys.iPv6]: yup.string(),
};
export const VMClaimModal = {
  initialValues: {
    [VMClaimModalKeys.iPv4]: "",
    [VMClaimModalKeys.iPv6]: "",
    [VMClaimModalKeys.iPv4AddressType]: "Dynamic",
    [VMClaimModalKeys.iPv6AddressType]: "Dynamic",
  },
  validationSchema: yup.object(vmClaimModalValidationBase),
};

export const SupportAdminVMClaimModal = {
  initialValues: {
    [VMClaimModalKeys.ownerId]: null,
    ...VMClaimModal.initialValues,
  },
  validationSchema: yup.object({
    [VMClaimModalKeys.ownerId]: yup.string().label('User').required().nullable(),
    ...vmClaimModalValidationBase,
  }),
};

export const VMPortsModalKeys = {
  ports: "ports",
  type: "type",
  protocol: "protocol",
};
export const VMPortsModal = {
  initialValues: {
    [VMPortsModalKeys.ports]: "",
    [VMPortsModalKeys.type]: "",
    [VMPortsModalKeys.protocol]: "",
  },
  validationSchema: yup.object({
    [VMPortsModalKeys.ports]: yup
      .string()
      .matches(/^\S*$/, "Remove spaces")
      .matches(
        /^([0-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-6][0-5][0-5][0-3][0-5])(,([0-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-6][0-5][0-5][0-3][0-5]))*$/,
        "Incorrect format"
      ) //numbers 0-65535, no spaces, comma separated
      .required("Add at least one port"),
    [VMPortsModalKeys.type]: yup.string().required("Choose a type"),
    [VMPortsModalKeys.protocol]: yup.string().required("Choose a protocol"),
  }),
};

export const DNSModalKeys = {
  address: "address",
};
export const DNSModal = {
  initialValues: {
    [DNSModalKeys.address]: "",
  },
  validationSchema: yup.object({
    [DNSModalKeys.address]: yup.string().required("Set address"),
  }),
};
