import React, { useState } from "react";
import { Status } from "components";
import "./Modal.css";

const Layout = ({
  tabsConfig,
  isLoaded,
  error,
  title,
  children,
  minHeight,
  handleHide,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const onTabClick = (index) => {
    setActiveTabIndex(index);
  };

  const renderTabs = (
    <div className="sc-modal-tabs">
      {tabsConfig?.map(({ text }, index) => (
        <div
          key={index}
          className={index === activeTabIndex ? "isActive" : ""}
          onClick={() => onTabClick(index)}
        >
          {text}
        </div>
      ))}
    </div>
  );

  const displayChosenTabContent = (children) => {
    const chosenChild = children[activeTabIndex];
    return {
      ...chosenChild,
      props: { ...chosenChild.props, isNoLayout: true },
    };
  };

  return (
    <div className="sc-modal-layout">
      <div className="sc-modal-background" />
      <div
        className="sc-modal-body-wrapper"
        role="document"
        style={{ minHeight }}
      >
        <div className="sc-modal-header">
          {tabsConfig && renderTabs}
          {title && <h5>{title}</h5>}
          <button type="button" className="close" onClick={handleHide}>
            &times;
          </button>
        </div>
        <Status isLoaded={isLoaded} error={error}>
          <div className="sc-modal-layout-children">
            {tabsConfig ? displayChosenTabContent(children) : children}
          </div>
        </Status>
      </div>
    </div>
  );
};

export default Layout;
