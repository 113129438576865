import React from 'react'
import { Form } from 'components/Modal'
import { Input } from 'components'

const FormModal = ({ isLoaded, error, currentRow, handleHide, handleSave, handleSetCredits }) => (
    <Form
        isLoaded={isLoaded}
        error={error}
        id={currentRow.id}
        title="Add credits to student"
        handleHide={handleHide}
        handleSave={handleSave}
    >
        <div>User email: {currentRow.userEmail}</div>
        <div>Credits balance: {currentRow.userCreditBalance}</div>
        <hr />
        <Input
            label="Number of credits"
            type="number"
            name="credits"
            min={1}
            placeholder={1}
            onChange={handleSetCredits}
        />
    </Form>
)

export default FormModal
