import axios from 'axios'
import URI from 'urijs'
import { MsalAuthProvider, LoginType } from 'react-aad-msal'

export const BASE_URL = process.env.REACT_APP_BASE_URL

const authConfig = {
    auth: {
        authority: process.env.REACT_APP_AUTHORITY,
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URL
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    },
    system: {
        tokenRenewalOffsetSeconds: 60
    }
}

const authenticationParameters = {
    scopes: ['user.read'],
    prompt: 'select_account'
}

export const authProvider = new MsalAuthProvider(authConfig, authenticationParameters, LoginType.Redirect)

export const API = {
    baseUrl: BASE_URL + '/api',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Client: 'student-teacher'
    },
    adminPanelHeader: {
        Client: 'admin-panel'
    }
}

export const axiosInstance = axios.create({
    headers: API.headers
})

export const axiosAdminInstance = axios.create({
    headers: { ...API.headers, ...API.adminPanelHeader }
})

const requestHandler = async request => {
    const {
        idToken: { rawIdToken: token }
    } = await authProvider.getIdToken()
    request.headers.Authorization = `Bearer ${token}`

    if (request.data?.isAppJsonHeader) {
        delete request.data.isAppJsonHeader
        request.headers['Content-Type'] = 'application/json'
    }

    if (request.isAppJsonHeader) {
        delete request.isAppJsonHeader
        request.headers['Content-Type'] = 'application/json'
    }

    const schoolId = localStorage.getItem('schoolId')
    if (schoolId) {
        request.params = { ...request.params, schoolId }
    }

    return request
}

axiosInstance.interceptors.request.use(requestHandler)
axiosAdminInstance.interceptors.request.use(requestHandler)

export const addQueryToUrl = (url, query) => {
    return new URI(url).addQuery(query || '').toString()
}

export const authorizationCheck = error => {
    const status = error && error.response && error.response.status
    if (status === 401) {
        window.authorization.status = false
        localStorage.removeItem('schoolId')
    }
}

export const adminGet = async ({ url, query }) => {
    return get({ url, query, localAxiosInstance: axiosAdminInstance })
}

export const get = async ({ url, query, responseType = 'json', localAxiosInstance = axiosInstance }) => {
    const uri = addQueryToUrl(url, query)
    try {
        const result = await localAxiosInstance.get(`${API.baseUrl}${uri}`, {
            responseType
        })
        return Promise.resolve(result)
    } catch (err) {
        authorizationCheck(err)
        return Promise.reject(err)
    }
}

export const adminPost = async ({ url, query, body }) => {
    return post({ url, query, body, localAxiosInstance: axiosAdminInstance })
}

export const post = async ({ url, query, body = {}, localAxiosInstance = axiosInstance }) => {
    const uri = addQueryToUrl(url, query)

    try {
        const result = await localAxiosInstance.post(`${API.baseUrl}${uri}`, {
            ...body,
            isAppJsonHeader: true
        })
        return Promise.resolve(result)
    } catch (err) {
        authorizationCheck(err)
        return Promise.reject(err)
    }
}

export const adminPut = async ({ url, query, body }) => {
    return put({ url, query, body, localAxiosInstance: axiosAdminInstance })
}

export const put = async ({ url, query, body = {}, localAxiosInstance = axiosInstance }) => {
    const uri = addQueryToUrl(url, query)

    try {
        const result = await localAxiosInstance.put(`${API.baseUrl}${uri}`, {
            ...body,
            isAppJsonHeader: true
        })
        return Promise.resolve(result)
    } catch (err) {
        authorizationCheck(err)
        return Promise.reject(err)
    }
}

export const remove = async ({ url, body = {}, localAxiosInstance = axiosInstance }) => {
    try {
        const result = await localAxiosInstance.delete(`${API.baseUrl}${url}`, {
            ...body,
            isAppJsonHeader: true
        })
        return Promise.resolve(result)
    } catch (err) {
        authorizationCheck(err)
        return Promise.reject(err)
    }
}

export const adminRemove = async ({ url, body = {} }) => {
    return remove({ url, body })
}

export const URLS = {
    groups: {
        all: '/groups',
        single: id => `/groups/${id}`, //NEW integrated
        allStudents: id => `/groups/${id}/students`, //NEW integrated
        byStudent: (groupId, studentId) => `/groups/${groupId}/students/${studentId}`
    },
    labs: {
        all: '/labs', //NEW integrated
        single: id => `/labs/${id}`, //NEW integrated
        publish: id => `/labs/${id}/publish`, //NEW integrated
        templates: id => `/labs/${id}/templates`, //NEW integrated
        virtualMachines: id => `/labs/${id}/virtual-machines`, //NEW integrated
        maxVmsPerLab: (id, amount) => `/labs/${id}/vms-per-lab/${amount}`, //NEW integrated
        maxVmsPerStudent: (id, amount) => `/labs/${id}/vms-per-student/${amount}`, //NEW integrated
        labDescription: id => `/labs/${id}/description`, //NEW integrated
        labStudents: id => `/labs/${id}/students`, //NEW integrated
        labName: id => `/labs/${id}/name`, //NEW integrated
        vmsPerLab: (id, amount) => `/labs/${id}/vms-per-lab/${amount}`, //NEW integrated
        vmsPerStudent: (id, amount) => `/labs/${id}/vms-per-student/${amount}`, //NEW integrated
        potentialVMOwners: id => `/labs/${id}/potential-vm-owners`
    },
    virtualMachines: {
        all: `/virtual-machines`, //no api endpoint
        action: (id, action) => `/virtual-machines/${id}/set-state/${action}`, //NEW integrated
        single: id => `/virtual-machines/${id}`, //NEW integrated
        rdp: id => `/virtual-machines/${id}/rdp`, //NEW integrated
        networkSecurityGroup: id => `/virtual-machines/${id}/network-security-group`, //NEW integrated
        setIP: id => `/virtual-machines/${id}/set-private-ip`, //NEW integrated
        templates: id => `/virtual-machines/${id}/template`,
        contributors: id => `/virtual-machines/${id}/contributors`,
        securityRules: id => `/virtual-machines/${id}/security-rules`,
        ipConfiguration: id => `/virtual-machines/${id}/ip-configuration`,
        templatesSingle: `/virtual-machines/template`,
        reserve: '/student/virtual-machines/reserve',
        allDns: vmId => `/virtual-machines/${vmId}/dns-servers`, //NEW integrated
        dnsById: (vmId, dnsServerId) => `/virtual-machines/${vmId}/dns-servers/${dnsServerId}`, //NEW integrated
        potentialContributors: id => `/virtual-machines/${id}/potential-contributors` //NEW integrated,
    },
    virtualNetworks: {
        all: '/virtual-networks', //NEW integrated
        subnets: labId => `/virtual-networks/${labId}` //NEW integrated
    },
    galleryImages: {
        all: '/gallery-images', //NEW integrated
        single: id => `/gallery-images/${id}` //NEW
    },
    images: {
        customImages: id => `/images/custom-images/lab/${id}`,
        vhd: '/images/custom-images/VHD',
        vhdByLab: () => `/images/custom-images/VHD/lab/${id}`
    },
    requests: {
        send: '/requests', //NEW integrated
        handledMessages: '/requests?requestType=handled', //NEW integrated
        unhandledMessages: '/requests?requestType=unhandled', //NEW integrated
        update: '/requests/update-request-credit'
    },
    securityRules: {
        all: '/security-rules', //NEW integrated
        byId: id => `/security-rules/${id}` //NEW integrated
    },
    templates: {
        all: '/templates' //NEW
    },
    sizes: '/sizes', //NEW integrated
    costs: (endpoint, startDate, endDate) =>
        `/costs${endpoint ? '/' + endpoint : ''}/start/${startDate}/end/${endDate}`,
    operations: '/operations',
    schools: {
        users: '/schools/users',
        settings: '/schools/settings', //NEW integrated
        all: '/schools'
    },
    student: '/student',
    teacher: '/teacher',
    users: {
        all: '/users', //NEW integrated
        dashboard: 'users/admin-overview', //NEW integrated
        teachers: '/users/teachers-admins', //NEW integrated,
        students: '/users/students' //NEW integrated,
    },
    teachers: '/users?role=teacher', //NEW integrated
    transactions: {
        labs: '/transactions/labs', //NEW integrated
        students: '/transactions/students', //NEW integrated
        teachers: '/transactions/teachers' //NEW integrated
    },
    authorization: '/users/info', //NEW integrated
    notificationHub: '/notification-hub', //NEW integrated
    regions: '/regions'
}
