import React from "react";
import {
  Box,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { Field, useField } from "formik";

const useStyles = makeStyles((theme) => {
  return {
    formControl: {
      width: "100%",
      "& .MuiInputBase-root .MuiSelect-root": {
        border: "2px solid #c4c4c4",
        borderRadius: "4px",
        padding: "7.5px 32px 7.5px 14px",
      },
      "& .MuiInputBase-root.Mui-error .MuiSelect-root, & .MuiInputBase-root.Mui-error.Mui-focused .MuiSelect-root": {
        borderColor: "#f44336",
      },

      "& .MuiInputBase-root.Mui-focused .MuiSelect-root": {
        borderColor: "#8ec154",
      },
      "& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after": {
        transition: "none",
        border: "none",
      },
      "& .MuiSelect-select:focus": {
        background: "#fff",
      },
    },
  };
});

const FormSelectContainer = ({
  name,
  config,
  values,
  label,
  boxClassName,
  className,
  m,
  p,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });
  const errorText = meta.error && meta.touched ? meta.error : "";

  const classes = useStyles();

  const renderValues = config.map(({ value, label }) => (
    <MenuItem key={label} value={value}>
      {label}
    </MenuItem>
  ));

  return (
    <Box m={m || "0 0 20px"} p={p} className={boxClassName}>
      <FormLabel error={!!errorText}>{label}</FormLabel>
      <FormControl className={classes.formControl} error={!!errorText}>
        <Field {...field} type="select" as={Select}>
          {renderValues}
        </Field>
        {errorText && <FormHelperText error={true}>{errorText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default FormSelectContainer;
