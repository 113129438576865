import React from "react";
import { Box, TextField, makeStyles } from "@material-ui/core";
import { useField } from "formik";

const useStyles = makeStyles((theme) => {
  return {
    textField: {
      width: "100%",
      "& .MuiInputBase-root": {
        border: "2px solid #c4c4c4",
        borderRadius: "4px",
      },
      "& .MuiInputBase-root input": {
        padding: "7px 14px 7px 7px",
      },
      "& .MuiInputBase-root:before, & .MuiInputBase-root:after, & .MuiInputBase-root:hover:before, ": {
        border: "none",
      },
      "& .Mui-focused": {
        borderColor: "#8ec154",
      },
      "& .MuiInputBase-root.Mui-error": {
        borderColor: "#f44336",
      },
      "& .MuiFormLabel-root, & .MuiFormLabel-root .Mui-focused": {
        top: "-7px",
        padding: "0",
        transform: "none",
        fontSize: "1rem",
        color: "rgba(0, 0, 0, 0.54)",
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: "#f44336",
      },
    },
  };
});

const FormInput = ({
  label,
  placeholder,
  multiline,
  type = "text",
  boxClassName,
  className,
  m,
  p,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  const classes = useStyles();

  return (
    <Box m={m || "0 0 20px"} p={p} className={boxClassName}>
      <TextField
        label={label}
        placeholder={placeholder}
        multiline={multiline}
        type={type}
        {...field}
        helperText={errorText}
        error={!!errorText}
        className={`${classes.textField} ${className}`}
      />
    </Box>
  );
};

export default FormInput;
