import { memo, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children }) => {
    const el = useRef(document.getElementById('sc-modal-root') || document.createElement('div'))
    const elCurrent = el.current
    const { body } = document

    useEffect(() => {
        body.appendChild(elCurrent)
        body.style.overflow = 'hidden'
        return () => {
            body.removeChild(elCurrent)
            body.style.overflow = 'auto'
        }
    }, [body, elCurrent])

    return createPortal(children, elCurrent)
}
export default memo(Portal)
