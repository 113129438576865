import { useState } from 'react'
import { handleShowNotification } from '@signpost/common/src/helpers/requestStatus'
import { getConnection, signalrConst } from './signalr'

const handleNotification = message => handleShowNotification('error', message, false)

export const useSignalr = () => {
    const [signalrConnection, setSignalrConnection] = useState()
    const [isSignalrConnection, setIsSignalrConnection] = useState(false)
    const [signalrValue, setSignalrValue] = useState(null)

    const initSignalr = async () => {
        const { connection, signalrStart } = await getConnection()
        setSignalrConnection(connection)
        try {
            signalrStart()
            setIsSignalrConnection(true)
        } catch (err) {
            console.log('Error while establishing connection => ' + err)
        }

        connection.on('Dispatcher', function(message) {
            switch (message.notificationType) {
                case signalrConst.VirtualMachineStateChangedNotification:
                    return setSignalrValue({
                        type: message.notificationType,
                        value: {
                            type: message.notificationType,
                            data: message.changedDto
                        }
                    })
                case signalrConst.VirtualMachineIPConfigurationChangedNotification:
                    return setSignalrValue({
                        type: message.notificationType,
                        value: {
                            type: message.notificationType,
                            data: message.changedDto
                        }
                    })
                case signalrConst.StoppingVirtualMachines:
                    return handleNotification('Stopping your virtual machine ' + message.changedDto.name)
                case signalrConst.VirtualMachineCreationFailedNotification:
                    return handleNotification(
                        `Virtual machine "${message.changedDto.name}" of template "${message.changedDto.template.templateName}" was not created because of Azure error. Try again later or contact the administrator.`
                    )
                case signalrConst.LackOfCreditsNotification: {
                    if (message.changedDto.lackOfCreditsNotificationType === signalrConst.StoppingVirtualMachines) {
                        return handleNotification('Level of your credits is critical. Stopping all Vms.')
                    }
                    if (message.changedDto.lackOfCreditsNotificationType === signalrConst.LowCreditsAlert) {
                        return handleNotification(
                            `Level of your credits is low (${message.changedDto.userRemainingAmountOfCredits}). Virtual machines are turned off when credits ballance reach the level of ${message.changedDto.creditsLevelForAutoShutdown} credits.`
                        )
                    }
                    return null
                }
                default:
                    return null
            }
        })
    }

    return { initSignalr, isSignalrConnection, setSignalrValue, signalrConnection, signalrValue }
}
