import { useState } from 'react'
import { get, URLS } from '@signpost/common/src/helpers/api'

export const useSchools = () => {
    const [schoolsData, setSchoolsData] = useState([])
    const [areSchoolsLoaded, setAreSchoolsLoaded] = useState(false)
    const [schoolsError, setSchoolsError] = useState(false)

    const fetchSchools = async () => {
        setAreSchoolsLoaded(false)
        try {
            const result = await get({ url: URLS.schools.all })
            setSchoolsData(result?.data || [])
        } catch (error) {
            setSchoolsError(error)
        } finally {
            setAreSchoolsLoaded(true)
        }
    }

    return { schoolsData, areSchoolsLoaded, schoolsError, fetchSchools }
}
