import { useState, useEffect } from 'react'
import { adminGet, get } from '@signpost/common/src/helpers/api'

export const useAdminGet = (endpoint, initialData = []) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [data, setData] = useState(initialData)
    const [error, setError] = useState(null)

    const getDataFromAPI = async () => {
        try {
            const url = `${endpoint.startsWith('/') ? '' : '/'}${endpoint}`
            const response = await adminGet({ url })
            setIsLoaded(true)
            setData(response.data)
        } catch ({ response }) {
            setIsLoaded(true)
            setError(response)
        }
    }

    useEffect(() => {
        getDataFromAPI()
    }, [])

    return { isLoaded, data, error, getDataFromAPI }
}

export const useGet = (endpoint, initialData = []) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [data, setData] = useState(initialData)
    const [error, setError] = useState(null)

    const getDataFromAPI = async () => {
        try {
            const url = `${endpoint.startsWith('/') ? '' : '/'}${endpoint}`
            const response = await get({ url })
            setIsLoaded(true)
            setData(response.data)
        } catch ({ response }) {
            setIsLoaded(true)
            setError(response)
        }
    }

    useEffect(() => {
        getDataFromAPI()
    }, [])

    return { isLoaded, data, error, getDataFromAPI }
}
