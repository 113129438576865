import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import './Header.css'
import logo from 'assets/logo-academic-software.svg'
import menu from 'assets/menu.svg'
import close from 'assets/close.svg'
import user from 'assets/user.svg'
import announcement  from '../announcement/index'







const Header = ({ userName, handleLogout }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen)
    const [anchorEl, setAnchorEl] = useState(null)

    const toggleOptions = e => {
        const wasEmailClicked = e.currentTarget.className === 'sc-header-email'
        if (wasEmailClicked) {
            return setAnchorEl(e.currentTarget)
        }
        return setAnchorEl(null)
    }

    return (
        <div className="sc-header">
            <a href=".">
                <img src={logo} alt="logo" className="sc-header-logo" />
            </a>
            <img src={isMenuOpen ? close : menu} alt="logo" className="sc-header-menu-icon" onClick={toggleMenu} />
            <ul className={isMenuOpen ? 'isMenuOpen' : ''}>
                <li>
                    <p className="sc-header-current-tab">Student Cloud</p>
                </li>
                <li>
                    <a href="https://www.academicsoftware.eu/dashboard" target="blank" className="sc-header-tab">
                        Software
                    </a>
                </li>
                <li>
                    <a href="https://signposthelp.zendesk.com/hc/en-us" target="blank" className="sc-header-tab">
                        Support
                    </a>
                </li>
                <li>
                    <p className="sc-header-email" onClick={toggleOptions}>
                        <img src={user} alt="logo" />
                        {userName}
                    </p>
                    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={toggleOptions}>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </li>
            </ul>        
        </div>
    )
}

export default Header
